// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

import nivelInicial from "../../../assets/images/nivelInicial.jpg";
import primaria from "../../../assets/images/primaria.jpg";
import secundaria from "../../../assets/images/secundaria.jpg";

function Niveles() {
  return (
    <MKBox component="section" py={5}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={9}
          sx={{ mx: "auto" }}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} lg={4} textAlign="center">
            <BackgroundBlogCard
              title="Nivel Inicial"
              description="En el Nivel Inicial, acompañamos a los más pequeños en su descubrimiento del mundo a través del juego y la exploración, sentando las bases para un aprendizaje feliz y significativo."
              image={nivelInicial}
              action={{
                type: "internal",
                route: "/somewhere",
                color: "info",
                label: "Ver mas",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} textAlign="center">
            <BackgroundBlogCard
              title="Primaria"
              description="En el Nivel Primario, brindamos una educación sólida que promueve el desarrollo integral de los estudiantes, cultivando habilidades clave y fomentando valores como el respeto y la responsabilidad."
              image={primaria}
              action={{
                type: "internal",
                route: "/somewhere",
                color: "info",
                label: "Ver mas",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} textAlign="center">
            <BackgroundBlogCard
              title="Secundaria"
              description="En el Nivel Secundario, preparamos a los jóvenes para enfrentar los desafíos del futuro, ofreciendo una formación académica rigurosa y fomentando el pensamiento crítico, la autonomía y el compromiso con la comunidad."
              image={secundaria}
              action={{
                type: "internal",
                // route: "/somewhere",
                color: "info",
                // label: "Ver mas",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Niveles;
