// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Historia() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={12} textAlign="center">
            <MKTypography variant="h5" align="center" my={5} color="secondary" textGradient>
              La obra educativa se basa en la pedagogía del amor y de la Misericordia y manifiesta
              las aptitudes del Corazón de Cristo. El mensaje cristiano tiene que penetrar en la
              educación para que llegue a la mente, y a la práctica de la vida. El valor de la
              unicidad y originalidad de la persona exige una educación personalizada e integral.
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={12} textAlign="center">
            <MKTypography variant="h5" align="center" my={5} color="secondary" textGradient>
              El respeto hacia todos, la acogida, la solidaridad y la reconciliación deben ser
              actitudes características del educador cabriniano, pues el testimonio es la fuerza
              estimuladora y operativo de la educación. Los programas educativos tienen que
              favorecer la sana ocupación del tiempo libre y de la diversión mediante iniciativas y
              actividades según las posibilidades y tradiciones locales.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Historia;
