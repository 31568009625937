// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

function Actividades() {
  return (
    <MKBox component="section" py={5}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={9}
          sx={{ mx: "auto" }}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} md={6} lg={6} xl={3} textAlign="center">
            <FilledInfoCard
              color="primary"
              icon="school"
              title="Calidad educativa"
              variant="contained"
              description="Ofrecemos una educación de calidad centrada en el desarrollo integral de nuestros estudiantes."
              sx={{ height: "300px" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={3} textAlign="center">
            <FilledInfoCard
              color="primary"
              icon="diversity_1"
              variant="contained"
              title="Convivencia"
              description="Promovemos un ambiente de convivencia basado en el respeto mutuo y la solidaridad, donde cada miembro de nuestra comunidad se siente valorado."
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={3} textAlign="center">
            <FilledInfoCard
              color="primary"
              icon="groups"
              variant="contained"
              title="Niveles"
              description="Desde el Nivel Inicial hasta el Secundario, ofrecemos una educación completa que prepara a los estudiantes para el futuro."
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={3} textAlign="center">
            <FilledInfoCard
              color="primary"
              variant="contained"
              icon="pool"
              title="Actividades"
              description="Ofrecemos una amplia gama de actividades extracurriculares que enriquecen la experiencia educativa de nuestros estudiantes."
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4} textAlign="center">
            <FilledInfoCard
              color="primary"
              icon="church"
              variant="contained"
              title="Ejemplo"
              description="Descripción"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} textAlign="center">
            <FilledInfoCard
              color="primary"
              variant="contained"
              icon="auto_stories"
              title="Ejemplo 2"
              description="Descripción 2."
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Actividades;
