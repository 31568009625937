/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-instituto.jpg";
// import logoLargo from "assets/images/logoGrande.jpg";
import { ContenedorPrincipal } from "components/ContenedorPrincipal";
import Counters from "./sections/Counters";
import Historia from "./sections/Historia";
import Actividades from "./sections/Actividades";
// import Information from "./sections/Information";
// import DesignBlocks from "./sections/DesignBlocks";
// import Pages from "./sections/Pages";
import Niveles from "./sections/Niveles";
import Pages from "./sections/Pages";
import BotonWsp from "components/BotonWsp";

function Presentation() {
  return (
    <MKBox position="relative">
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        sticky
        brand={"Instituto Sagrado Corazón"}
      />
      {/* <Grid container display="flex" alignItems="center" justifyContent="center">
        <Grid p={1} xs={12} sm={12} md={6}>
          <MKBox
            component="img"
            src={logoLargo}
            alt={"Logo institucional"}
            width="100%"
            mt={8}
            borderRadius="lg"
          />
        </Grid>
      </Grid> */}
      <MKBox
        minHeight="65vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
        borderRadius="lg"
        top="0px"
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKBox textAlign="center">
              <MKTypography
                variant="h1"
                color="white"
                mt={-6}
                mb={1}
                fontWeight="bold"
                fontFamily="Montserrat"
                fontSize={{ xs: "2rem", xl: "4rem", lg: "3rem" }}
                sx={
                  (({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  }),
                  {
                    textShadow:
                      "2px 2px 2px black, -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black",
                  })
                }
              >
                Instituto Sagrado Corazón de Jesús{" "}
              </MKTypography>
              <MKTypography
                variant="h5"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
                sx={{
                  textShadow:
                    "2px 2px 2px black, -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black",
                  fontFamily: "-apple-system",
                }}
              >
                Villa Mercedes, San Luis, Argentina.
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <ContenedorPrincipal>
        <Counters />
        <Niveles />
        <Historia />
        <Actividades />
        <Pages />
      </ContenedorPrincipal>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <BotonWsp />
    </MKBox>
  );
}

export default Presentation;
