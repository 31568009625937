import { WhatsApp } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import MKBox from "components/MKBox";
import React from "react";

export default function BotonWsp() {
  return (
    <MKBox
      position="fixed"
      bottom="5%"
      right="5%"
      p={1}
      sx={{
        cursor: "pointer",
        backgroundColor: "#e8e8e8",
        borderRadius: "50%",
      }}
    >
      <MKBox display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Tooltip placement="top" title="Contactar por Whatsapp">
          <WhatsApp
            color="success"
            fontSize="large"
            sx={{
              width: "60px",
              height: "60px",
            }}
            onClick={() => {
              window.open("https://wa.link/q81tly");
            }}
          />
        </Tooltip>
      </MKBox>
    </MKBox>
  );
}
