/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import sagradoCorazonDeJesus from "assets/images/sagradoCorazonDeJesus.jpg";
import { ContenedorPrincipal } from "components/ContenedorPrincipal";
import NoticiasFetch from "./sections/NoticiasFetch";
import BotonWsp from "components/BotonWsp";

// import Information from "./sections/Information";
// import DesignBlocks from "./sections/DesignBlocks";
// import Pages from "./sections/Pages";

function Noticias() {
  return (
    <MKBox position="relative">
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        sticky
        brand={"Instituto Sagrado Corazón"}
      />
      <MKBox
        minHeight="65vh"
        width="100%"
        sx={{
          backgroundImage: `url(${sagradoCorazonDeJesus})`,
          // backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "grid",
          placeItems: "center",
        }}
        borderRadius="lg"
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKBox textAlign="center">
              <MKTypography
                variant="h1"
                color="primary"
                mt={-6}
                mb={1}
                fontWeight="bold"
                fontFamily="Montserrat"
                fontSize={{ xs: "3rem", md: "5rem" }}
                sx={
                  (({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  }),
                  {
                    textShadow:
                      "1px 1px 1px black, -1px -1px 1px black, 1px -1px 1px black, -1px 1px 1px black",
                  })
                }
              >
                {/* Noticias{" "} */}
              </MKTypography>
              <MKTypography
                variant="h4"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
                sx={{
                  textShadow:
                    "2px 2px 2px black, -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black",
                  fontFamily: "-apple-system",
                }}
              ></MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <ContenedorPrincipal>
        <NoticiasFetch />
        {/* <Pages /> */}
      </ContenedorPrincipal>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <BotonWsp />
    </MKBox>
  );
}

export default Noticias;
