/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  const fecha = new Date();
  const fechaNacimiento = new Date("1901-05-26");
  const cantidadAnios = fecha.getFullYear() - fechaNacimiento.getFullYear();
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              color="primary"
              count={cantidadAnios}
              title="Años de nuestro instituto"
              description="Desde el 26 de Mayo de 1901"
            />
          </Grid>
          <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="center">
            <Divider
              orientation="vertical"
              sx={{ display: { xs: "none", md: "block" }, mx: "auto" }}
            />
            <DefaultCounterCard
              count={1100}
              color="primary"
              suffix="+"
              title="Cantidad de estudiantes"
              description="La ciudad nos elige desde hace más de 100 años"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
