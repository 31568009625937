// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/secundaria.jpg";
import { ContenedorPrincipal } from "components/ContenedorPrincipal";
import BotonWsp from "components/BotonWsp";

function EstructuraCurricular() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky brand={"Instituto Sagrado Corazón"} />
      <MKBox
        minHeight="65vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          backgroundRepeat: "no-repeat",
        }}
        borderRadius="lg"
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKBox textAlign="center">
              <MKTypography
                variant="h1"
                color="primary"
                mt={-6}
                mb={1}
                fontWeight="bold"
                fontFamily="Montserrat"
                fontSize={{ xs: "2rem", xl: "4rem", lg: "3rem" }}
                sx={
                  (({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  }),
                  {
                    textShadow:
                      "2px 2px 2px black, -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black",
                  })
                }
              >
                Estructura curricular{" "}
              </MKTypography>
              <MKTypography
                variant="h4"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
                sx={{
                  textShadow:
                    "2px 2px 2px black, -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black",
                  fontFamily: "-apple-system",
                }}
              ></MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <ContenedorPrincipal>
        <MKBox textAlign="center">
          <MKTypography variant="h1" fontWeight="medium" color="primary" py={3}></MKTypography>
        </MKBox>
        <Grid px={5} container spacing={1}>
          <Grid item xs={12}>
            <h3 class="post-title entry-title" itemprop="name">
              ESTRUCTURA CURRICULAR ISC
            </h3>
            <div
              class="post-body entry-content"
              id="post-body-7816145348320291959"
              itemprop="description articleBody"
            >
              <div></div>
              <div class="MsoNormal">
                <b>
                  <span lang="ES-TRAD">
                    <span>NIVEL SECUNDARIO</span>
                  </span>
                </b>
              </div>
              <div class="MsoNormal">
                <br />
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ESTRUCTURA CURRICULAR&nbsp; – Dcto. 7163/16</span>
                  </b>
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">
                      Bachiller en Economía y Administración Cohorte 2017&nbsp;&nbsp;{" "}
                    </span>
                  </b>
                </div>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">
                      Espacios curriculares y cargas horarias por Año – Ciclo Básico:{" "}
                    </span>
                  </b>
                </div>
                <table
                  align="left"
                  border="1"
                  cellpadding="0"
                  cellspacing="0"
                  class="MsoNormalTable"
                  s
                >
                  <tbody>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <b>
                            <span lang="ES-TRAD">ESPACIO CURRICULAR</span>
                          </b>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <b>
                            <span lang="ES-TRAD">1º año</span>
                          </b>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <b>
                            <span lang="ES-TRAD">2º año</span>
                          </b>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <b>
                            <span lang="ES-TRAD">3º año</span>
                          </b>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Lengua</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">6</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">5</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">5</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Matemática</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">6</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">5</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">5</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Historia</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Geografía</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Biología</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Física</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Química</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Lengua extranjera</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">4</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">4</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">4</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Educación Tecnológica</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Educación Física</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">4</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Formación Ética y Ciudadana</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Educación artística</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Catequesis</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Total de Espacios curriculares</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">11</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">12</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">12</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Total semanal horas cátedras</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">40</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">40</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">40</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpFirst">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>

                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>

                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <b>
                    <span lang="ES-TRAD">&nbsp;</span>
                  </b>
                </div>
                <div class="MsoListParagraphCxSpMiddle">
                  <br />
                  <br />
                  <b>
                    <span lang="ES-TRAD">Catequesis*:</span>
                  </b>
                  <span lang="ES-TRAD">
                    {" "}
                    Espacio curricular&nbsp; que sustenta la identidad y&nbsp; misión de la
                    institución, se encuentra incorporada a la Enseñanza del Nivel Medio del
                    Instituto con la misma rigurosidad, sistematicidad y criterios de promoción y
                    acreditación que el resto de las disciplinas curriculares.{" "}
                  </span>
                </div>
                <div class="MsoListParagraphCxSpMiddle"></div>
                <div class="MsoListParagraphCxSpLast">
                  <br />
                  &nbsp;
                  <b>
                    <span lang="ES-TRAD">CICLO ORIENTADO: ECONOMÍA Y ADMINISTRACIÓN</span>
                  </b>
                </div>
                <table
                  align="left"
                  border="1"
                  cellpadding="0"
                  cellspacing="0"
                  class="MsoNormalTable"
                  s
                >
                  <tbody>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <b>
                            <span lang="ES-TRAD">ESPACIO CURRICULAR</span>
                          </b>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <b>
                            <span lang="ES-TRAD">4º año</span>
                          </b>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <b>
                            <span lang="ES-TRAD">5º año</span>
                          </b>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <b>
                            <span lang="ES-TRAD">6º año</span>
                          </b>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Matemática </span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">4</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">4</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">4</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Lengua y Literatura </span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Biología </span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Física</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Química</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Historia</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Geografía</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Filosofía</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Psicología</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Educación artística </span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Política y Ciudadanía </span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Educación Física</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Lengua extranjera </span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Formación para la Vida y el Trabajo</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Educación Tecnológica </span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <b>
                            <span lang="ES-TRAD">FORMACIÓN ESPECÍFICA</span>
                          </b>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">_</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Sistema de Información Contable</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">4</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">4</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">4</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Administración</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Economía</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">3</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Derecho</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">-</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">4</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Espacios de Opción Institucional</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">2</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Total de Espacios curriculares</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">13</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">14</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">14</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">
                        <div class="MsoNormal">
                          <span lang="ES-TRAD">Total &nbsp;horas cátedras</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">38</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">41</span>
                        </div>
                      </td>
                      <td valign="top">
                        <div align="center" class="MsoNormal">
                          <span lang="ES-TRAD">42</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <br />
              </div>
              <div></div>
            </div>
          </Grid>
        </Grid>
      </ContenedorPrincipal>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <BotonWsp />
    </>
  );
}

export default EstructuraCurricular;
