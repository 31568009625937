// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/madrecabrini-768x490.jpg";
import cabriniEsquina from "assets/images/ALONSOESQUINA.jpg";
import { ContenedorPrincipal } from "components/ContenedorPrincipal";
import BotonWsp from "components/BotonWsp";

function MadreCabrini() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky brand={"Instituto Sagrado Corazón"} />
      <MKBox
        minHeight="65vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          backgroundRepeat: "no-repeat",
        }}
        borderRadius="lg"
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKBox textAlign="center">
              <MKTypography
                variant="h1"
                color="primary"
                mt={-6}
                mb={1}
                fontWeight="bold"
                fontFamily="Montserrat"
                fontSize={{ xs: "2rem", xl: "4rem", lg: "3rem" }}
                sx={
                  (({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  }),
                  {
                    textShadow:
                      "2px 2px 2px black, -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black",
                  })
                }
              >
                Madre Cabrini{" "}
              </MKTypography>
              <MKTypography
                variant="h4"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
                sx={{
                  textShadow:
                    "2px 2px 2px black, -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black",
                  fontFamily: "-apple-system",
                }}
              ></MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <ContenedorPrincipal>
        <MKBox textAlign="center">
          <MKTypography variant="h1" fontWeight="medium" color="primary" py={3}>
            Su historia
          </MKTypography>
        </MKBox>
        <Grid px={3} container spacing={1}>
          <Grid item xs={12} sm={8} md={8} lg={9}>
            <div>
              <MKTypography>
                &nbsp; La Fundadora de las Misioneras del Sagrado Corazón, Madre Francisca Javier
                Cabrini, vivió desde la segunda mitad del siglo XIX hasta el año 1917.
              </MKTypography>
              <MKTypography>
                &nbsp; Nació en Lombardía, en Sant´Angelo Lodigiano, Italia, el 15 de julio de 1850
                en el seno de una familia de una profunda fe, creciendo junto a dos hermanas y un
                hermano mayores que ella. Tuvo otros siete hermanos que fallecieron tempranamente.
              </MKTypography>
              <MKTypography>
                &nbsp; Sus padres fueron Agostino Cabrini y Stella Oldini, ambos de una enorme fe
                que supieron transmitir a sus hijos. Su hermana Rosa era mayor que ella y un
                verdadero modelo de vida que Madre Cabrini supo seguir. En su infancia se hizo
                espontáneo y cada vez más consciente y comprometido el sueño de ser religiosa
                misionera deseando poner en el corazón de quienes se le acercaban el amor seguro y
                decidido que se estaba desarrollando en el suyo.
              </MKTypography>
              <MKTypography>
                En sus primeros años de vida, Madre Cabrini había soñado espacios sin fronteras
                llenando barquitos de papel con violetas en el Vénera, pequeño riachuelo de Livraga,
                donde jugaba cuando era pequeña, viéndolos alejarse llevados por la corriente y
                pensando que las flores eran sus futuras Hermanas que viajaban a misionar. Sus
                sueños maduraron en el dolor transformándose en sólidos ideales a favor de los más
                necesitados.
              </MKTypography>
              <MKTypography>
                Contaba que el día de su Confirmación, cuando aún tenía siete años, sentía algo que
                casi no podía explicar, le pareció no estar ya sobre la tierra, teniendo su corazón
                pleno de un gozo purísimo.
              </MKTypography>
              <MKTypography>
                Ya en su adolescencia Francisca Cabrini se inscribió en la Escuela Normal de Arluno,
                pueblo muy cercano a Sant`Angelo, a fin de graduarse de maestra de escuela.
              </MKTypography>
              <MKTypography>
                Dicha Escuela estaba en manos de las Hijas del Sagrado Corazón, que formaban e
                instruían a las futuras maestras. Ahí vivió Madre Cabrini durante casi cinco años,
                hasta 1868, año en que se graduó.
              </MKTypography>
              <MKTypography>
                Siguiendo la costumbre de la época, las internas vivían en el convento con las
                religiosas. El progreso espiritual de Francisca Cabrini, que estaba a la altura de
                las religiosas en espíritu de oración y de sacrificio, fue enorme.
              </MKTypography>
              <MKTypography>
                Es así como pidió su ingreso como religiosa en las Hijas del Sagrado Corazón, ya que
                su atracción por la vida consagrada había sido constante desde sus primeros años.
                Vivió seis años en la Casa de la Providencia, lugar donde recibió el hábito
                religioso.
              </MKTypography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <img
              src={cabriniEsquina}
              alt="Madre Cabrini"
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
          </Grid>

          <MKTypography>
            Su formación religiosa dio uno de sus mayores frutos el día 14 de noviembre de 1880. Ese
            día, a los 30 años de edad, junto a otras 7 religiosas, emprende la maravillosa y osada
            misión de crear una congregación misionera en Codogno (Ciudad italiana situada a 60 Km.
            de Milán, a 58 metros sobre el nivel del mar, en el bajo Lodigiano).
          </MKTypography>
          <MKTypography>
            Esta localidad bañada por las cristalinas aguas de tres ríos Po, Llambro y Ticino va a
            ser el epicentro del Proyecto Cabriniano, impulsado por las proféticas palabras del
            Obispo de Lodi Monseñor Domenico Gelmini: "Tu quieres hacerte misionera, yo no conozco
            Instituto misionero, funda tu uno".
          </MKTypography>
          <MKTypography>
            Un antiguo convento franciscano, abandonado desde la época de Napoleón, se convirtió en
            el lugar elegido como matriz de su accionar misionero que trascendió Europa y se propagó
            por toda América y el mundo, y cuya finalidad fue, y es, el inagotable amor del Corazón
            de Jesús. En dicho convento inició su Instituto de las Misioneras del Sagrado Corazón,
            como quiso llamarlo, defendiendo con firmeza aquel título de "Misioneras".
          </MKTypography>
          <MKTypography>
            Allí ingresaron, un 10 de noviembre de 1880, las siete hermanas. Es interesante recrear
            el momento a través de las memorias del Instituto: "por la noche, Madre Cabrini sin
            conocer la casa, en la oscuridad nos acompaño a cada una a su cuarto y daba la impresión
            que ella conociera todo el convento al verla como se movía con tanta seguridad" … "la
            casa estaba vacía y a las Hermanas les hacía falta todo. Para la escasa comida no tenía
            ni los cubiertos suficientes, algunas comían con el tenedor, otras con la cuchara … pero
            tenía paz y alegría desbordantes".
          </MKTypography>
          <MKTypography>
            En una de las humildes habitaciones del ex convento Madre Cabrini con sus compañeras
            improvisaron una capilla donde el 14 de noviembre de 1880 Monseñor Serrati celebró la
            Santa Misa, las religiosas hicieron voto de profesión y Madre Cabrini agregó a su nombre
            el de Javier en recuerdo de San Francisco Javier, Patrono de las Misiones.
          </MKTypography>
          <MKTypography>
            Este es el lugar desde donde se irradió el Amor de Jesús al Mundo a través del accionar
            de una mujer aparentemente frágil pero de una fuerza espiritual ciclópea que funde su
            corazón en el de Cristo e impulsada por Él es que se entrega a los más necesitados, a
            los desamparados y a los que sufren.
          </MKTypography>
          <MKTypography>
            Por ese motivo es que en Codogno se encuentra la reliquia más preciada de Madre Cabrini:
            "su corazón", el cual se encuentra en una Iglesia que se construyó después de su muerte,
            denominada Tabor.
          </MKTypography>
          <MKTypography>
            Además se halla la habitación de la Santa con algunas de sus pertenencias, custodiada y
            conservada con mucho amor por las Hermanas.
          </MKTypography>
          <MKTypography>
            Este cuarto ha sido testigo de sus largas vigilias, de interminables e íntimos coloquios
            con Dios. Allí redactó las Reglas de su Instituto y escribió numerosas cartas postulares
            y de agradecimiento.
          </MKTypography>
          <MKTypography>
            Por eso se ha convertido en la actualidad en un sitio de peregrinación y oración para la
            grey católica Universal. Resulta movilizante poder contemplar los numerosos mensajes que
            diferentes peregrinos deslizan bajo la almohada de la cama que pertenecía a la Santa,
            buscando alivio a los problemas que los aquejan.
          </MKTypography>
          <MKTypography>
            En ese entonces era imposible predecir que esa joven maestra, con una clara y precoz
            vocación religiosa, llegaría a realizar 67 fundaciones en América y Europa, en el
            transcurso de sus 67 años de existencia. Fueron proféticas las palabras del Papa León
            XIII, su protector y amigo: "Acércate Cabrini, tienes el espíritu de Dios, llévalo por
            todo el mundo."
          </MKTypography>
          <MKTypography>
            Su mayor atracción era el Corazón de Jesús, soñando con la misión en China. Sin embargo,
            cuando la Iglesia, en la persona del Papa León XIII, le indicó el camino de los
            emigrantes en América, ahí dirigió sus pasos convirtiéndose para todos en compañía y
            esperanza.
          </MKTypography>
          <MKTypography>
            Madre Cabrini que desde pequeña soñaba con ir a Oriente a misionar, entendió que la
            voluntad de Dios, expresada por las palabras del Papa León XIII, era venir a América,
            más precisamente a Nueva York adonde habían llegado muchos inmigrantes italianos que
            debían enfrentarse a duras condiciones de vida tanto en lo material como en lo
            espiritual: tratando de sortear la barrera del idioma, de insertarse en una sociedad que
            no los recibía como hermanos.
          </MKTypography>
          <MKTypography>
            Así, sin más, entregando su misión al Sagrado Corazón, también se embarcó rumbo a
            América, a ponerse en contacto con aquellos hombres y mujeres que pretendían comenzar
            una nueva vida en otro lugar lejano a su tierra natal, a brindarles su apoyo.
          </MKTypography>
          <MKTypography>
            Y no fueron sólo palabras. Fue fundadora de escuelas, de asilos y de hospitales. Visitó
            con sus hermanas a los más débiles, a los pequeños de Dios: a los huérfanos, a los
            enfermos, a los presos. Amó en ellos al mismo Jesús.
          </MKTypography>
          <MKTypography>
            Madre Cabrini comprendía el sufrimiento de los inmigrantes porque ella misma se había
            alejado de su Patria. Pero jamás se había alejado de la voluntad de Dios. Y allí
            radicaba su fuerza espiritual. Allí estaba el secreto de la grandiosidad de su obra.
          </MKTypography>
          <MKTypography>
            Así fue como se embarcó hacia América en La Haya, el 23 de marzo de 1889, en la nave de
            carga "Bourgogne" a bordo de la cual viajaban más de 1.500 inmigrantes. Durante la
            travesía se desató una tormenta pasando duras pruebas.
          </MKTypography>
          <MKTypography>
            El 31 de marzo, las primeras siete "Misioneras del Sagrado Corazón de Jesús" llegaron a
            suelo americano. No les resultó fácil instalarse en Nueva York, Ciudad a la cual
            arribaron primeramente, ya que la casa donde debían hospedarse no existía más. Su
            decidida intención de permanecer en suelo americano hace que organicen una escuela para
            inmigrantes antes que el orfanato que pensaban crear.
          </MKTypography>
          <MKTypography>
            Poco tiempo después de su llegada a la Ciudad de Nueva York, en junio de 1889, las
            Hermanas visitaban los apartamentos más pobres, los subterráneos donde vivían
            amontonados los emigrantes, y entraban en lugares donde ni siquiera la policía se
            atrevía a ingresar sin protección; haciéndose emigrantes con los emigrantes para poder
            auxiliar a los miles de italianos que se encontraban sumidos en la pobreza y la
            desolación, y a los niños que quedaban solos lustrando zapatos por las calles mientras
            sus padres estaban sometidos a las tareas más pesadas para poder sobrevivir.
          </MKTypography>
          <MKTypography>
            En el año 1890 fundó el noviciado americano con otras siete misioneras. Más tarde partió
            con catorce hermanas para Nicaragua. Después de un viaje largo y dificultoso, en el mes
            de diciembre de 1891 inauguró el Colegio de Granada. Luego decidió regresar a Nueva York
            recorriendo la costa atlántica pasando por la zona habitada por las comunidades
            indígenas Misskito, realizando un viaje nada fácil y llevando consigo sólo a una joven
            indígena como intérprete. No obstante las dificultades trató de ayudar a estas
            comunidades de América. Después de haber estado nuevamente en Roma y de haber obtenido
            audiencia con el Papa, éste la impulsa a proseguir con su obra. En los planes de Madre
            Cabrini había llegado la hora de la República Argentina, hacia donde se habían dirigido
            centenares de inmigrantes.
          </MKTypography>
          <MKTypography>
            El objeto de este viaje fue realizar una fundación en nuestro País, donde su celo
            misionero encontró un vasto campo de acción y también posibilidades para que sus
            Hermanas se establecieran. Esta vez la Santa Misionera emprendió un viaje largo y
            fatigoso, partiendo de Panamá hacia la República Argentina a través de la Cordillera de
            los Andes. Con estas palabras las Hermanas de Panamá, despidieron a Madre Francisca
            Javier: "¡Qué viaje tan largo y difícil emprende ahora la Madre!". Era el 12 de octubre
            de 1895 y junto a la Hermana Clara inició el fatigoso viaje, que incluyó una escala en
            Lima, lugar en donde la Madre se encomienda a Santa Rosa, en el momento de visitar su
            tumba. Debió enfrentarse ante todo con la fatiga de una navegación lenta a lo largo de
            las costas de América Meridional, bañadas por el Océano Pacífico. Luego, tuvo que
            soportar la difícil travesía de la inmensa cadena montañosa abriéndose camino por entre
            la nieve.
          </MKTypography>
          <MKTypography>
            El 23 de noviembre de 1895, desde Santiago de Chile, parte la caravana organizada por la
            Compañía Transandina y Francisca Cabrini figura entre los viajeros. Así, llegó a nuestro
            País a través de la Cordillera de los Andes y llevó a cabo esa empresa a los cuarenta y
            cinco años con la misma desenvoltura con que había desafiado otras empresas espirituales
            anteriores. El viaje en su totalidad duró desde el 12 de octubre al 1 de diciembre de
            1895, llegando a un país, el nuestro, donde no tenía personas conocidas. Sin embargo, en
            poco tiempo pudo realizar un trabajo que a todos les pareció un milagro por los frutos
            que dejó. Los primeros días de diciembre de 1895, las Hermanas Clara y Francisca se
            hospedaron en la casa de las Hermanas de la Misericordia, contando con la ayuda del
            Padre Broggi para contactarse con las autoridades civiles y eclesiásticas de la Ciudad
            de Buenos Aires. A pesar de los obstáculos existentes, especialmente el idioma, y
            después de mucho caminar en busca de una casa que le permitiera concretar su deseo de
            establecer un centro educativo en esta Ciudad, Madre Cabrini encuentra el lugar ideal,
            dando origen así a su primera fundación en la República Argentina.
          </MKTypography>
          <MKTypography>
            De este modo, el 8 de mayo de 1896 abre sus puertas, en honor a la Santa de Lima, el
            Colegio Santa Rosa en pleno centro porteño. Posteriormente, parte desde la Ciudad de
            Buenos Aires, en agosto de 1896, hacia la de Barcelona.
          </MKTypography>
          <MKTypography>
            Trayendo consigo las mayores gracias, la Reverendísima y venerada Madre Fundadora,
            Francisca Javier Cabrini, después de su segunda llegada a la Argentina se puso con todas
            las fuerzas a expandir y difundir el Instituto en varias provincias que se lo habían
            solicitado. Durante su permanencia en la Ciudad de Buenos Aires trasladó un Colegio, que
            había fundado en su viaje anterior, al barrio de Caballito y en el barrio de Flores
            abrió un orfanato para los emigrantes más pobres. Desde Buenos Aires dispuso enviar dos
            hermanas, cuyos nombres eran Costanza Cambieri y Ana Lombardi, a la Ciudad de Mendoza
            para realizar la fundación de una nueva institución. Sin embargo, la misma no pudo
            realizarse y, luego de cuarenta días, la Madre Cabrini hace que abandonen dicha Ciudad y
            emprendan el regreso.
          </MKTypography>
          <MKTypography>
            El día 13 de abril de 1901 dichas dos Hermanas arriban a la actual Ciudad de Villa
            Mercedes de la Provincia de San Luis, resueltas a no marcharse sin antes dar todos los
            pasos posibles que tendiesen a abrir el camino para una nueva fundación allí. En esta
            última Ciudad se comunicaron con el Padre Felix Gómez, quien no podía él sólo atender a
            todas las necesidades de los feligreses de la zona. Ambas recibieron hospedaje en la
            casa de la señora Mercedes Ortiz de Ramallo, la cual mostró un gran gesto de generosidad
            con esta acción y, posteriormente, también dio hospedaje a Madre Cabrini. Asimismo,
            muchas familias se mostraron deseosas e interesadas en que las recién llegadas pensaran
            en la fundación de un colegio para los niños de la Ciudad.
          </MKTypography>
          <MKTypography>
            Mientras recorrían Villa Mercedes las dos Hermanas fueron recibiendo donaciones de
            dinero y bienes muebles para comenzar con su obra de parte de sus generosos vecinos. Por
            ese entonces, año 1901, Madre Cabrini se encontraba difundiendo el Instituto en la
            Ciudad de Rosario de la Provincia de Santa Fe, abocada a la tarea de la fundación de un
            Colegio Internacional. Posteriormente, llega en tren ella en persona a la Ciudad de
            Villa Mercedes el día 23 de mayo de 1901 a las 16:30 horas. Las dos hermanas aludidas,
            junto a un gran número personas, se reunieron para esperarla, lo cual alegró mucho su
            llegada y una inmensa felicidad inundó su corazón. No obstante el cansancio del largo
            viaje, Madre Cabrini intercambió los augurios y felicitaciones. Luego tomó un breve
            descanso y quiso salir en busca de una casa para fundar un Colegio. Ese mismo día la
            encuentra y el 24 de mayo le hacen entrega de las llaves.
          </MKTypography>
          <MKTypography>
            Narra la tradición que un niño vendiendo un casal de palomas blancas se encontraba en el
            lugar y que se las ofreció a la Madre, quien vislumbró en este hecho una señal del
            Espíritu Santo. Este inmueble se hallaba en la intersección de las calles Pringles y
            Pedernera de la Ciudad de Villa Mercedes. Señaliza el sitio histórico en la actualidad
            un prominente relieve en bronce, ejecutado por el escultor Roberto Tessi, quien ideó y
            plasmó su creación en los talleres de la Escuela Superior de Bellas Artes "Antonio E.
            Agüero". Dicho relieve se inauguró el 22 de noviembre de 1995. Para poder encontrar
            dicha casa, recorrió toda la Ciudad de Villa Mercedes hasta encontrar una que le pareció
            adecuada y la mejor de las ya visitadas. Sin embargo, es interesante señalar que cuando
            venía transitando por la calle principal (hoy Avenida Mitre 1222), a Madre Cabrini le
            quedo claro que ese era el lugar ideal para construir el colegio que actualmente
            funciona en esa dirección, pues consideraba que era el centro geográfico de la Ciudad,
            sueño que se hizo realidad en la década del sesenta del siglo XX.
          </MKTypography>
          <MKTypography>
            Se puede decir que, apenas llegó, ya encontró el local para el Colegio con aquella
            actividad y energía que la distinguían en todos los trabajos que proyectaba en bien de
            las personas. Al otro día, muy temprano, visitó a las familias que le habían prestado
            colaboración a sus hijas en el momento de su arribo a la Ciudad. Envió un telegrama
            llamando a lagunas Hermanas, que estaban en la Ciudad de Buenos Aires, para constituir
            la primera comunidad de la Ciudad de Villa Mercedes. Además las proveyó de todo lo
            necesario para desarrollar sus vidas allí. Cabe destacar que para abrir el nuevo Colegio
            se había solicitado autorización y realizado todos los trámites correspondientes ante el
            Consejo de Educación de la Provincia de San Luis. Luego de haberla obtenido, Madre
            Cabrini peticionó a dicho Organismo Educativo el envío de algunos útiles y muebles para
            el equipamiento del mismo. A continuación se detalla la lista de lo solicitado: 40
            bancos, 6 mesitas escritorios, 2 pizarrones, 1 pizarrón cuadricular, 3 escuadras y
            compases, 2 semicírculos graduados, 3 tableros contador, 1 esfera terrestre, 1 colección
            de sólidos, 1 plancha de botánica, 1 de zoología, 1 de minerología, 1 colección de
            carteles para la lectura, 1 juego completo de mapas, 1 mapa de la República Argentina, 1
            mapa de la Provincia de San Luis, útiles para gimnasia y perchas. Esta nota fue enviada
            a su vez por el destinatario a la oficina de depósitos para que se informara del
            material disponible.
          </MKTypography>
          <MKTypography>
            Como se puede inferir del listado precedente, Madre Cabrini, como buena docente, conocía
            las necesidades de un establecimiento educativo y bregaba por una educación integral de
            las niñas, no descuidando ningún aspecto. El 25 de mayo de 1901, aniversario de la
            Revolución de Mayo de 1810, fue invitada por las autoridades civiles a los actos
            conmemorativos, asistiendo ella a los mismos acompañada por Sor Francisca Merati. En
            relación a esto último, quiero mencionar que hoy, después de tantos años, nos colma de
            emoción imaginar a Madre Cabrini caminando por las calles de nuestra querida Ciudad de
            Villa Mercedes, trasladándose desde la estación del Ferrocarril hacia el corazón de la
            misma, por esas arterias, en el otoñal y ventoso clima del mes de mayo, conversando con
            los comerciantes y pobladores del primer año del siglo XX y compartiendo una de sus
            principales fechas Patrias. El 26 de mayo de 1901 fue ella personalmente a esperar a la
            Estación Ferroviaria a las Hermanas que, arribadas de la Ciudad de Buenos Aires, venían
            para formar la comunidad de Villa Mercedes; recibiéndolas con sincero y maternal afecto.
            Las recién llegadas eran: Madre Nazzarena Scalvini, Madre Pierina Scollavizza y Sor
            Concetta Arnaboldi
          </MKTypography>
          <MKTypography>
            Ese mismo día, 26 de mayo de 1901, Santa Francisca Javier Cabrini fundó el "Colegio
            Sagrado Corazón" en la Ciudad de Villa Mercedes, el cual ya cumplió más de cien años
            brindando formación a varias generaciones. Madre Cabrini fue su primera Directora. A
            fines de junio de 1901 la comunidad de Villa Mercedes despidió con gran pesar a Madre
            Cabrini, quien tuvo que viajar a la Ciudad de Buenos Aires para ocuparse de las otras
            fundaciones, quedando como Superiora de la flamante Comunidad Religiosa la Hermana
            Constanza Cambieri. No obstante ello, desde la Capital Federal, continuó mandando todo
            lo necesario para sus hijas y su fundación escolar. Mientras tanto las Hermanas
            repartían sus días ayudando en la Parroquia a través de la catequesis, visitando a los
            enfermos y prisioneros, visitando a las familias de las quintas y campos de los
            alrededores, además de reparar y acondicionar el edificio del Colegio, para lo cual se
            hizo necesario pedir colaboraciones y organizar rifas. En cierta ocasión que las
            Hermanas recorrían la Ciudad de Villa Mercedes con dicho propósito, un hombre les donó
            $20.-, suma que era importante para la época. Las religiosas no imaginaron que dicho
            señor, llamado Henry de Boucherville, de origen francés, sería el futuro benefactor de
            la Comunidad.
          </MKTypography>
          <MKTypography>
            Henry de Boucherville, maravillado por poder comunicarse en su lengua materna con las
            Hermanas, quienes habían residido un largo tiempo en la Casa fundada en París por la
            Santa Madre, les ofrece un carruaje para que pudieran trasladarse a lugares más
            alejados, solicitándole a su vez que se contactaran y atendieran las necesidades de la
            colectividad francesa allí existente. Años más tarde, el generoso protector deberá
            retornar a su Patria natal, dejando a las Hermanas el mobiliario de su casa y
            solicitando a su apoderado, el señor Valentín Moyetta, la entrega del monto $ 200
            anuales, extraídos de la renta de sus propiedades. A principio del año 1908, éste
            enviará desde París un delicado templete para la exposición del Santísimo Sacramento,
            dos ángeles, un vía crucis, varias cajas de láminas con representaciones religiosas y un
            hermoso cáliz para el capellán del Colegio. En el mes de Octubre de 1909 la Superiora de
            la Casa, Hermana Pía Dona, recibe una epístola del Señor Boucherville en la que éste
            expresa su deseo de donar una manzana y media de terreno, donde vivían en ese momento
            dos ahijados suyos (el matrimonio Clemente y Jacques Pechín, de 64 y 66 años), ubicada
            sobre las calles Mitre, Corrientes y una calle sin abrir "para el naciente", y otra
            manzana "para el poniente" situada entre las arterias San Luis, Mitre, 3 de febrero
            (actual Pedernera) y una calle sin abrir.
          </MKTypography>
          <MKTypography>
            En cuanto a la primera propiedad, ocupada por los esposos Pechín, no se podía
            usufructuar hasta el fallecimiento de ambos, debiendo bregar las Hermanas por la
            atención de los ancianos. Luego de consultar a Madre Cabrini se acepta esta donación,
            siendo éste el lugar donde actualmente se encuentra emplazado el "Colegio Sagrado
            Corazón". El trabajo en la casa era mucho. Tenían que visitar a los enfermos en los
            momentos de dolor, para infundirles palabras de aliento, y enseñar el catecismo a los
            niños. Los primeros años transcurrieron sin grandes novedades, la nueva Casa se
            distinguía por el elevado nivel de enseñanza. Así fue creciendo el Colegio en número de
            alumnos y se fueron realizando los arreglos necesarios para mejorarla cada vez más.
          </MKTypography>
          <MKTypography>
            La pujante Escuela Primaria de las misioneras recibía año tras año una mayor cantidad de
            alumnos . En la década del cuarenta del siglo XX comienzan a dictarse también clases de
            bordados y cocina. A fines de la década del cuarenta reciben alumnos varones pero sólo
            en primer grado superior. En los años cincuenta se ven en la necesidad de construir un
            nuevo edificio más grande, comenzando así el sueño del nivel secundario. El nuevo
            Colegio se construyó en el terreno que había elegido Madre Cabrini cincuenta años atrás.
            Ello fue posible, como se dijo precedentemente, gracias a la donación del terreno y
            dinero para la construcción del edificio por parte de los hermanos BOUCHERVILLE. Así fue
            creciendo la construcción de la nueva Casa con planta alta lista para una escuela
            secundaria, abriendo sus puertas en el año 1962.
          </MKTypography>
          <MKTypography>
            El nuevo edificio albergó durante años a niñas de poblaciones cercanas en las cuales no
            podían realizar sus estudios secundarios En la actualidad en la República Argentina hay
            en total siete colegios pertenecientes a la Congregación. Dos de ellos en la Ciudad de
            Villa Mercedes de la Provincia de San Luis, uno en la Ciudad de Rosario de la Provincia
            de Santa Fe, otro en la Provincia de Buenos Aires y tres en la Ciudad de Buenos Aires.
            Ya en la Ciudad de Buenos Aires, Madre Cabrini recorrió durante siete meses todo el
            territorio pampeano, a pesar de la fastidiosa fiebre intermitente que padecía. Toda esta
            gran obra se realiza a partir de Madre Cabrini, la cual era una religiosa frágil, débil,
            hasta enfermiza desde su niñez. Pero se atrevió a soñar. A soñar con una misión
            grandiosa. Pero también supo reconocer su propia debilidad y darse cuenta que nada se
            alcanza sin la ayuda de Dios Usó su libertad para entregarse y ser una herramienta para
            ayudar a otros; eligió obedecer la voluntad divina, confiar en la Providencia, esperar o
            actuar cuando Dios se lo pedía. Porque sabía "escucharlo"; porque comprendía lo que Él
            esperaba de ella.
          </MKTypography>
          <MKTypography>
            Pero no fue pasiva. Todo lo contrario. Era actividad, era ejecución, era decisión. Se
            atrevió a soñar. Pero además, se atrevió a dejarse llevar por los caminos por donde su
            Amado Jesús la llevara. Por eso el valor cabriniano que queremos compartir hoy, a través
            del presente proyecto, es la capacidad de tener un corazón con alas, capaz de trascender
            las propias fronteras, para acoger al que es diverso, para compartir con el extranjero
            el mismo ideal de fraternidad. Hoy, en este nuevo tiempo de postmodernismo, en el que
            existe una crisis de valores , nuestro país no escapa a ella. Y la herencia de Santa
            Francisca Javier debe ser vivida por nosotros, para hacer que el Amor sea el eje
            alrededor del cual vuelva a girar nuestra realidad atravesada por el egoísmo, el
            individualismo y la indiferencia generalizados. Si amamos, como lo hizo Madre Francisca
            Javier Cabrini, todo lo que hagamos irá de la mano de los valores superiores. Al decir
            de San Agustín : "Ama y haz lo que quieras". Y según las propias palabras de Madre
            Cabrini : "No es tiempo para que el amor permanezca oculto, sino para que se manifieste
            vivo y verdadero". Lo dijo hace un siglo y aun hoy tiene una vigencia inusitada.
          </MKTypography>
          <MKTypography>
            Porque el amor no es una palabra. No existe si no se traduce en hechos concretos, como
            los realizados por Madre Cabrini y sus Hermanas, que signifiquen ponerse al servicio del
            ser humano, y hacer ni más ni menos que lo que nos corresponde hacer a cada uno, desde
            nuestro lugar, desde el aquí y el ahora. Es importante señalar que dentro de las
            Misiones Cabrinianas también existe el voluntariado, para que nadie se quede fuera. Se
            trata generalmente de jóvenes que todavía no se han establecido del todo en un trabajo
            estable y por lo tanto pueden ofrecer uno o dos años de trabajo misionero. Ellos van
            voluntariamente a cualquier lugar del mundo donde están las Misioneras del Sagrado
            Corazón. Hacen una dura preparación, en misiones locales al principio y luego en el
            exterior. Pero nunca inferior a un año. Como podemos apreciar, en la familia cabriniana
            para misionar hay espacio siempre y en todo lugar. Ello lo inició Madre Cabrini, la cual
            fue adónde el Corazón de Jesús le pedía que fuera, viendo en los ojos de los más
            necesitados, los desarraigados, los pobres, al mismo Jesús. Para eso tuvo que renunciar
            a sí misma y entregar su vida misionera.
          </MKTypography>
          <MKTypography>
            Santa Francisca Javier Cabrini nunca descuidaba lo que se le pedía realizar en bien de
            los demás, hasta llegar al límite de las posibilidades. Su entrega fue especialmente
            realizada a favor de los emigrantes. En la población mundial, una persona de cada
            cincuenta es emigrante. Actualmente, más de 130 millones de personas viven en un país
            diferente de aquel en donde nacieron. Por las calles de cada continente se encuentran
            las víctimas del hambre, de la guerra, del genocidio, de las violaciones de los derechos
            humanos, del desequilibrio entre los países ricos y los países pobres. La América de
            Madre Cabrini, de fines del siglo XIX y principios del XX, era el refugio de los
            campesinos y de los jornaleros italianos que escapaban de la miseria, así como también
            el de personas de otras nacionalidades. Ella se arriesgaba con audacia, junto con sus
            Hermanas, en los barrios urbanos más poblados de inmigrantes y más peligrosos, en las
            minas, en los campos más aislados, etc.; siempre impulsada por la compasión y por la
            firme decisión de hacer todo lo posible para aliviar los sufrimientos y la soledad,
            promoviendo a las personas con dignidad y sosteniéndolas en la esperanza. En un período
            que fue de profunda transformación social y de enorme sufrimiento para los inmigrantes,
            casi siempre olvidados y explotados, no había espacio para la indiferencia.
          </MKTypography>
          <MKTypography>
            Ella escribió en el año 1906: "...son muy pocos los que saben que nuestro Instituto
            utiliza sus fuerzas principales para socorrer las necesidades materiales y morales de
            nuestros pobres italianos en el extranjero. Pero después será para todos de gran
            satisfacción ver que hay quién, sin hacer ruido pero con el trabajo y fatiga ímproba,
            contribuye para poner en alto el nombre italiano en países que no se contentan con las
            palabras sino con los hechos y hechos reales." La apreciación de la identidad cultural
            de los emigrantes que buscan la integración en los países de acogida será un tema
            constante en Madre Cabrini. Su contribución con su ejemplo y sus reflexiones en lo que
            se refiere a la atención pastoral de los emigrantes, se derivaba del deseo de encontrar
            una adecuada solución a sus problemas personales y sociales. Como se dijo, Madre Cabrini
            era maestra diplomada y las instituciones educativas y de asistencia se fueron
            estableciendo con una generosa determinación y rápidos movimientos. Junto a estas
            instituciones se fue formando la comunidad de los emigrantes, manteniendo su sentido de
            pertenencia y teniendo presente el papel clave de la comunidad emigrada.
          </MKTypography>
          <MKTypography>
            El Papa Pío XII, al proclamarla Patrona Universal de los Emigrantes, la definió como a
            "Madre de los Emigrantes". El Papa Juan Pablo II le agregó después: "Madre afectuosa e
            incansable". En efecto, la Madre Cabrini al describir a sus Hermanas, escribió una nota
            autobiográfica sobre la femineidad en la pastoral de la emigración: ..."le llaman madres
            y hermanas, y ellos (los emigrantes) sienten que esas palabras no son vacías porque
            saben que esos títulos corresponden a corazones verdaderamente maternales, que palpitan
            juntos y que dejan de pensar en sí mismas, ellas hacen suyos sus intereses, sus penas,
            sus alegrías...". Otra de sus mayores preocupaciones era los niños. Desde el inicio, su
            programa fue: "Tomamos a los niños desde los 4 a los 15 años de edad; les damos una
            casa, alimento y vestidos, los formamos física y mentalmente para que sean buenos
            ciudadanos ....y ojalá pronto podamos hacer algo más." El sentido de la urgencia y su
            ímpetu interior, en poco tiempo le permitió el desarrollo de las obras con intuiciones
            de método que todavía hoy sorprenden.
          </MKTypography>
          <MKTypography>
            A Madre Cabrini le interesaba toda la persona con sus realidades física y espiritual,
            sin condicionamientos. Orfanatos, escuelas y hospitales surgieron en las distintas
            ciudades y países, manteniendo el contacto con los seres humanos también a través de las
            visitas a las familias y a los prisioneros. Su dinamismo misionero y espiritual fue
            uniendo y coordinando todo, transformándolo en fuente de creatividad aún en los momentos
            de crisis. Su desaparición física se produjo el 22 de diciembre de 1917, dejando 67
            fundaciones y alrededor de 1300 Misioneras. Fue beatificada el 13 de noviembre de 1938,
            canonizada el 7 de julio de 1946 y proclamada Patrona de los Emigrantes en el año 1950.
            América se conmovió ante la noticia de su desaparición física, proclamándola, los
            inmigrantes, su Santa, antes aún de que fuese promovida la causa de su canonización.
          </MKTypography>
          <MKTypography>
            Ella, a pesar de los años transcurridos desde su fallecimiento, continúa siendo más
            actual que nunca y habla con su ejemplo y con sus enseñanzas. En el torbellino de las
            migraciones actuales, su figura nos muestra el camino de la acogida y de la solidaridad
            para alcanzar un presente y un futuro de buena convivencia enriquecedora. El Papa Juan
            Pablo II, el 19 de febrero de 1995, nos ha recordado que la construcción de este futuro
            "requiere corazones maternos como aquel de Madre Cabrini, enriquecidos con el potencial
            del ánimo femenino y refinado con el amor evangélico.". De este modo, la obra misionera,
            emprendida por Madre Cabrini en 1880 y continuado por las Misioneras del Sagrado Corazón
            hasta el día de hoy, se ha ido desarrollando y transformando para responder a los
            acontecimientos históricos que la han ido estimulando y que continúan motivándola. Con
            el pasar del tiempo, a través de los años, el Instituto de las Misioneras del Sagrado
            Corazón se ha visto reorganizado más de una vez pero siempre ha conservado el espíritu
            infundido por su Fundadora. Tanto es esto así que, luego de más de un siglo, el lema
            elegido por la Congregación para celebrar y conmemorar estos 125 años de la Fundación
            del Instituto es: "Madre Cabrini, una herencia para disfrutar y compartir"
          </MKTypography>
          <MKTypography>
            Desde los inicios de su Fundación, las Misioneras del Sagrado Corazón se han dedicado a
            trabajar siempre al servicio de la gente más necesitada. Su actividad se brindó en los
            sectores donde es posible la promoción humana integral (educación formal y popular), la
            defensa de la vida (hospitales, dispensarios, etc.), la opción por los pobres y por los
            más pobres y abandonados (obras sociales, actividades comunitarias, etc.). Actualmente,
            las Misioneras del Sagrado Corazón trabajan en Europa (Italia, Francia, Inglaterra,
            Suiza, Portugal, España, Siberia); en América (Argentina, Estados Unidos, Brasil,
            Guatemala, Nicaragua); en África (Swaziland); en Asia (Taiwan); en Oceanía (Australia).
            Las Misioneras eligen la inserción, es decir, compartir la vida de los pobres,
            participando de sus esperanzas, de sus inseguridades y de sus frustraciones. Todo desde
            la mística de la fragilidad y de la debilidad, como alternativa a la mentalidad de
            fuerza y de poder, típica de nuestro mundo de la eficiencia. En el espíritu de esta
            mística han surgido nuevas comunidades de inserción en Brasil, en Argentina, en Europa y
            en Estados Unidos.
          </MKTypography>
          <MKTypography>
            Al mismo tiempo, se ha desarrollado un estilo de vivir en comunidad confiando en la
            centralidad de la misión más que en una estructura comunitaria formada solamente por
            Hermanas. Queremos también por el presente rendir nuestro homenaje y reconocimiento a
            tantas Hermanas Misioneras que trabajan en el anonimato y que aceptan el reto de
            emprender nuevas sendas y hacer posible las nuevas iniciativas cabrinianas, a los laicos
            colaboradores, a los Voluntarios y a las Misioneras Laicas Cabrinianas. Entre estas
            Hermanas aludidas en el párrafo precedente debemos mencionar a la Superiora General
            Madre Lina Colombini que es actualmente la máxima autoridad a nivel mundial de la
            Congregación de las Misioneras del Sagrado Corazón residente en Roma y a la Hermana
            Stella Maris Elena que es la máxima autoridad en la República Argentina. Finalmente,
            debemos expresar que, así como la Madre Cabrini manifestó solidaridad con los emigrantes
            italianos, que eran en aquel tiempo un símbolo concreto de marginación; hoy la Misión
            Cabriniana se encuentra orientada hacia los inmigrantes y los refugiados de las
            distintas sociedades, los niños abandonados, las mujeres explotadas y maltratadas, los
            mayores, los jóvenes, los enfermos y las familias, brindando a todos su apoyo y
            contención. Es por todas estas razones que solicitamos a nuestros pares la aprobación
            del presente proyecto de declaración.
          </MKTypography>
          <MKTypography>Liliana T. Negre de Alonso.</MKTypography>
        </Grid>
      </ContenedorPrincipal>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <BotonWsp />
    </>
  );
}

export default MadreCabrini;
