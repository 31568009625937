// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDt7PqTzJtR_Tn7U2HzU9GRBEfYl6ptNb0",
  authDomain: "noticiassagradovm.firebaseapp.com",
  projectId: "noticiassagradovm",
  storageBucket: "noticiassagradovm.appspot.com",
  messagingSenderId: "497062450814",
  appId: "1:497062450814:web:d2fd11b384b6d34dd2ea2f",
  measurementId: "G-FFLLMR4YJ4",
};

const app = initializeApp(firebaseConfig);

// Get Auth instance
const auth = getAuth(app);
const database = getFirestore(app);

export { app, auth, database };
