// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/secundaria.jpg";
import { ContenedorPrincipal } from "components/ContenedorPrincipal";
import BotonWsp from "components/BotonWsp";

function NormasDeConvivencia() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky brand={"Instituto Sagrado Corazón"} />
      <MKBox
        minHeight="65vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          backgroundRepeat: "no-repeat",
        }}
        borderRadius="lg"
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKBox textAlign="center">
              <MKTypography
                variant="h1"
                color="primary"
                mt={-6}
                mb={1}
                fontWeight="bold"
                fontFamily="Montserrat"
                fontSize={{ xs: "2rem", xl: "4rem", lg: "3rem" }}
                sx={
                  (({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  }),
                  {
                    textShadow:
                      "2px 2px 2px black, -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black",
                  })
                }
              >
                Normas de convivencia{" "}
              </MKTypography>
              <MKTypography
                variant="h4"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
                sx={{
                  textShadow:
                    "2px 2px 2px black, -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black",
                  fontFamily: "-apple-system",
                }}
              ></MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <ContenedorPrincipal>
        <MKBox textAlign="center">
          <MKTypography variant="h1" fontWeight="medium" color="primary" py={3}></MKTypography>
        </MKBox>
        <Grid px={5} container spacing={1}>
          <Grid item xs={12}>
            <div
              class="post hentry uncustomized-post-template"
              itemprop="blogPost"
              itemscope="itemscope"
              itemtype="http://schema.org/BlogPosting"
            >
              <h3 class="post-title entry-title" itemprop="name">
                NORMAS DE CONVIVENCIA
              </h3>
              <div class="post-header">
                <div class="post-header-line-1"></div>
              </div>
              <div
                class="post-body entry-content"
                id="post-body-8809381480631734318"
                itemprop="description articleBody"
              >
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">
                      <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      </span>
                    </span>
                  </b>
                  <b>
                    <span lang="ES-TRAD">
                      "ASOCIACION COLUMBUS" -<span> </span>“INSTITUTO SAGRADO CORAZÓN”
                    </span>
                  </b>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-TRAD">
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                  <span lang="ES-TRAD">
                    Av. Mitre 1222 Nº (5730) Villa Mercedes (S. L.)<span> </span>Tel. 02657-
                    421183.-{" "}
                  </span>
                </div>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <span lang="ES-TRAD">
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                  <b>
                    <span lang="ES-TRAD">CONTRATO EDUCATIVO AÑO 2013</span>
                  </b>
                  <span lang="ES-TRAD">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>-NORMAS DE CONVIVENCIA-
                  </span>
                </div>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 1º): FIN DE NUESTRA TAREA EDUCATIVA:</span>
                  </b>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-TRAD">
                    El INSTITUTO SAGRADO CORAZÓN de las HERMANAS MISIONERAS DEL SAGRADO CORAZÓN DE
                    JESÚS, de la Ciudad de Villa Mercedes, Prov. de San Luis, se propone:{" "}
                  </span>
                </div>
                <ul>
                  <li>
                    <span lang="ES-TRAD">
                      a) Humanizar y personalizar al alumno en la cultura Cristiana;{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      b) Formar integralmente al alumno con el sello del amor misericordioso del
                      Corazón de Cristo;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      c) Constituir una comunidad educativa centrada en el Corazón de Cristo, que
                      comparte el mismo proyecto educativo y promueve la formación integral por
                      medio de la relación interpersonal; procurando la síntesis fe- cultura y fe-
                      vida mediante la asimilación sistemática y crítica de la realidad y una
                      auténtica evangelización.-
                    </span>
                  </li>
                </ul>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 2º): DERECHOS DE LOS ALUMNOS:</span>
                  </b>
                </div>
                <ol>
                  <li>
                    <span lang="ES-TRAD">
                      Los alumnos del Instituto tienen derecho a exponer de manera adecuada y
                      respetuosa sus opiniones, plantear sus inquietudes, proponer proyectos, etc.,
                      ante las autoridades y en los tiempos oportunos;
                    </span>
                  </li>
                  <li>
                    <span>
                      A que sus inquietudes tengan respuesta y que se les respete en su
                      individualidad; siempre que las inquietudes lo sean dentro del marco académico
                      y que el educando guarde el debido respeto a las respuestas suministradas por
                      la Institución.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      A un trato respetuoso y deferente como personas, miembros de la comunidad
                      educativa del Instituto;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      A que las clases les sean impartidas en forma oportuna, eficiente y de acuerdo
                      con las normas establecidas
                    </span>
                    <span lang="ES-AR">.-</span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 3º) DEBERES DE LOS ALUMNOS</span>
                  </b>
                  <span lang="ES-TRAD">:</span>
                </div>
                <ol>
                  <li>
                    <span lang="ES-TRAD">
                      <span></span>Respetar los principios de la Institución;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Reconocerse en proceso de crecimiento, y por tanto aceptar consejos y
                      orientaciones que les ayuden a desarrollar armoniosamente sus aptitudes
                      físicas, morales, intelectuales y religiosas;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Cumplir íntegramente las tareas estudiantiles, en un marco de solidaridad,
                      honestidad y responsabilidad;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Guardar el debido respeto hacia todos los miembros de la comunidad educativa;
                      y observar buena conducta dentro y fuera del Colegio;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span></span>Cumplir las normas y disposiciones emanadas de la Representante
                      Legal y de la Dirección del Colegio;{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Presentar hasta el vencimiento del plazo fijado, el documento de evaluación,
                      parte de amonestaciones, autorizaciones para retiros anticipados y toda otra
                      documentación que emitan las autoridades del Instituto para notificar a sus
                      padres;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Prestar colaboración cuando el curso quede a cargo de los Preceptores, por
                      ausencia de un docente u otro motivo;{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Utilizar correctamente los bienes muebles y las instalaciones del Colegio, así
                      como sus pertenencias;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Asistir puntualmente a clase y otras actividades programadas por el Colegio
                      (dentro y fuera sea en las instalaciones de Av. Mitre y/o del Playón Deportivo
                      sito en calle de Lisandro De La Torre Nº 266), conduciéndose con aplicación y
                      buena educación;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      No organizar actividades que involucren directa o indirectamente al Colegio,
                      sin expresa autorización de sus directivos;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Participar activamente en su formación espiritual y actividades pastorales que
                      se realicen en el transcurso de la tarea educativa;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Mostrar el debido respeto a los símbolos patrios y religiosos;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      En caso de necesitar el alumno usar telefonía en horario de clase deberá
                      avisar previamente al preceptor y/o docente a cargo, y previa autorización
                      llamar desde el teléfono fijo del colegio o en su defecto con telefonía móvil
                      pero fuera del aula.-
                    </span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 4º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    :<b>LOS ALUMNOS NO PODRÁN:</b>
                  </span>
                </div>
                <ol>
                  <li>
                    <span lang="ES-TRAD">
                      Exhibir insignias, divisas, emblemas y/o distintivos que afecten la moral
                      cristiana, la organización constitucional o a las autoridades del Estado;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Usar maquillajes, tatuajes, piercings y/u otros elementos ajenos al uniforme;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Usar o distribuir láminas, folletos o cualquier otro mate-rial contrario a la
                      fe y a la moral cristiana;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Causar daños y/o deterioros de ningún tipo respecto de las instalaciones y/o
                      muebles del Colegio,
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Organizar y/o tomar parte en actos de indisciplina co-lectiva;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Llevar al Colegio libros, papeles, revistas, filmadoras, radios, cámaras
                      fotográficas y/o digitales, discman, walkman, equipos de audio, tablets,
                      naipes ni ningún otro objeto que no tenga relación con el estudio.- Respecto
                      del uso de celulares, si bien se permite al alumno ingresar con telefonía
                      móvil, queda expresamente prohibido tener los equipos encendidos durante los
                      horarios de clases;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Portar o hacer uso de objetos cortantes, armas de cualquier tipo, elementos de
                      pirotecnia, material explosivo, inflamable y/o de cualquier otra naturaleza
                      que pongan en peligro la seguridad de las personas y/o bienes de la comunidad
                      educativa; siendo motivo ello de graves sanciones y reservándose el Colegio en
                      su caso el derecho de efectuar las denuncias pertinentes;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      No pueden en horarios de clase usar telefonía, y en caso de urgencia avisar al
                      preceptor y/o docente a cargo.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Usar las netbooks/notebooks sin supervisión de los docentes;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Conectarse a INTERNET, redes sociales ni correos electrónicos durante la
                      jornada escolar, prohibición que abarca tanto las horas de clase como los
                      recreos y horas libres (salvo que la conexión obedezca a fines pedagógicos)
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      El Colegio no se hace responsable del extravío de útiles, dinero u otros
                      objetos de valor.-----
                    </span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 5º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    :<b>UNIFORME:</b>
                  </span>
                </div>
                <ol>
                  <li>
                    <span lang="ES-AR">
                      Los alumnos deben concurrir al Colegio diariamente con el correspondiente
                      uniforme.- El uso del mismo significa pertenencia al Instituto y
                      predisposición para el trabajo escolar.-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Es obligatorio el uniforme completo, aseado, planchado en toda actividad
                      educativa y mesas de exámenes.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      <u>Niñas</u>: Pollera pantalón a una altura de hasta 5 cm. sobre la rodilla,
                      remera blanca con<span>&nbsp; </span>logo del colegio, medias ¾ azules. En
                      tiempos de bajas temperaturas can can azul,<span>&nbsp; </span>zapatos negros,
                      pullover o chaleco azul, abrigo azul. <u>Nivel Inicial</u>: uniforme igual,
                      con delantal, todo con nombre completo. Durante tiempo de temperaturas cálidas
                      chaqueta.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      <u>Varones</u>: Pantalón gris, remera blanca con logo del colegio,
                      <span>&nbsp; </span>medias ¾ azules zapatos negros (no zapatillas), pullover o
                      chaleco azul, abrigo azul. <u>Nivel Inicial</u>: uniforme igual, con delantal,
                      todo con nombre completo. Durante tiempo de temperaturas cálidas chaqueta.{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      <u>Educación Física</u>: Equipo de gimnasia azul, remera blanca con logo del
                      colegio (sin distintivos o marcas comerciales), medias blancas y calzado
                      deportivo negro o blanco, buzo azul, sin ribetes de colores.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      En cuanto al aspecto personal los alumnos/as, tendrán que asistir aseados, con
                      el pelo ordenado (la niñas con el cabello recogido con colín azul o blanco y
                      los varones con el cabello corto), sin tinturas, los varones afeitados, para
                      ambos la cara despejada, uñas recortadas, limpias y sin pintura.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      En cuanto a los accesorios no podrán traer aquellos que por su tamaño, color o
                      cantidad sean ajenos al uniforme; tampoco usar maquillajes, tatuajes,
                      piercing.-------
                    </span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 6º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    :<b>ASISTENCIAS:</b>
                  </span>
                </div>
                <div class="MsoNormal">
                  <u>
                    <span lang="ES-AR">NIVEL INICIAL</span>
                  </u>
                  <span lang="ES-AR">: </span>
                </div>
                <div class="MsoNormal">
                  <ol>
                    <li>
                      <span lang="ES-AR">
                        El horario de ingreso es a las 9:00 a.m. (turno mañana) y a las 14:OO p.m.
                        (turno tarde), los alumnos que lleguen tarde deberán esperar acompañados de
                        sus Padres o Tutores en silencio y no interrumpir las actividades que se
                        llevan a cabo hasta su finalización. Pasado el horario establecido no estará
                        permitido el ingreso, salvo por una única vez y previa consulta a los
                        directivos y compromiso del padre o tutor que no volverá a suceder.-{" "}
                      </span>
                    </li>
                  </ol>
                </div>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <u>
                    <span lang="ES-AR">NIVEL PRIMARIO</span>
                  </u>
                  <span lang="ES-AR">.</span>
                </div>
                <ol>
                  <li>
                    <span lang="ES-AR">
                      La entrada al Colegio en el turno de la mañana es a las 7.55 hs. La llegada
                      tarde de 15 minutos será considerada media falta (se le dará tratamiento
                      adecuado a cada caso particular). En el turno de la tarde el horario de
                      entrada es 13.10 hs. La llegada tarde de 15 minutos será considerada media
                      falta.-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      En lo referente a las inasistencias, se tendrá en cuenta el régimen de
                      reincorporación de los alumnos de la<span>&nbsp;</span>Provincia de San Luis
                      que indica que deberán cumplir con el 75% de asistencias por trimestre. Serán
                      justificadas pero se contabilizan las inasistencias por las que se presente
                      certificado médico o la correspondiente notificación del padre o tutor.-
                    </span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <u>
                    <span lang="ES-AR">NIVEL SECUNDARIO</span>
                  </u>
                  <span lang="ES-AR">.</span>
                </div>
                <ol>
                  <li>
                    <span lang="ES-AR">
                      La entrada al Colegio es a las 7.50 hs. La llegada tarde de 15 minutos será
                      considerada media falta (se le dará tratamiento adecuado a cada caso
                      particular).{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      En lo referente a las inasistencias se tendrá en cuenta el régimen de
                      reincorporación de los alumnos de la Prov. de San Luis que indica para los
                      alumnos regulares un límite de faltas de 24 (veinticuatro), con la falta 25
                      (veinticinco) quedan libres. La primera reincorporación se expide en la
                      inasistencia número 15 (quince), debiéndose tener 17 (diecisiete)
                      inasistencias justificadas para poder contar con la posibilidad de una
                      extensión de 3 (tres) inasistencias más luego de la 25 (veinticinco). El
                      alumno libre deberá rendir escrito y oral todos los espacios curriculares
                      correspondientes al año escolar en curso para ser promovido al siguiente.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Cada inasistencia a Educación física y/o a Computación será considerada media
                      falta (desde 2º año en adelante).{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Cuando el alumno es retirado con anticipación o ingresa por motivos
                      justificados más tarde del horario de entrada se computará media inasistencia
                      justificada.{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Será autorizado a retirarse del Colegio únicamente el alumno cuando dicho
                      retiro sea requerido por escrito o personalmente por el Padre o Tutor.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Es obligación de los Padres, Tutores y alumnos respetar los horarios de
                      entrada y salida, con una tolerancia de diez (10) minutos.-{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Las inasistencias a clases serán justificadas personalmente por el Padre o
                      Tutor en el Cuaderno de comunicaciones<span>&nbsp; </span>el día en que se
                      reintegrare el alumno al Colegio; vencido ese plazo será considerada como
                      inasistencia injustificada.-{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Cuando las inasistencias sean motivadas por enfermedad, el Padre o Tutor,
                      deberá dar cuenta inmediata de ello durante el transcurso de la jornada,
                      presentando el certificado médico correspondiente al reintegrarse el alumno.-
                      Asimismo no se computarán como inasistencias las faltas a clase, si las mismas
                      se producen por la asistencia del alumno a actividades organizadas por la
                      Institución (misiones, torneos, competencias, etc.).-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Cuando las inasistencias sean por tres días consecutivos o más, solo podrán
                      justificarse con el correspondiente certificado médico.-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Las ausencias en los días de evaluaciones y/o prácticos avisados, solo podrán
                      ser justificadas de acuerdo al apartado 8).- Caso de incumplimiento el alumno
                      recibirá la calificación<span>&nbsp; </span>mínima correspondiente a la
                      evaluación y/o práctico.-{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Cuando las inasistencias sean motivadas por situaciones imprevistas y/o
                      extraordinarias y/o acontecimientos fortuitos, el Padre o Tutor deberá
                      notificar la ausencia en el transcurso de la jornada y justificar con
                      certificado médico en caso de enfermedad, o personalmente y por escrito si
                      fuere por situaciones extraordinarias, al momento de reintegrarse a clase el
                      alumno. Este es requisito indispensable para que la inasistencia se considere
                      “justificada”.{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      El incumplimiento de estos requisitos y condiciones puede determinar la no
                      reinscripción del alumno al Colegio.-
                    </span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 7º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    :<b>DISCIPLINA:</b>
                  </span>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-TRAD">
                    Quien no acepte las normas reglamentarias, disposiciones emanadas de las
                    autoridades del Colegio y/o de la Dirección<span>&nbsp; </span>y/o combata el
                    Proyecto Educativo, debe cuestionarse seriamente su permanencia en el Colegio.-
                    El Instituto se reserva la facultad de cancelar la matrícula vigente así como la
                    de no matricular para el curso lectivo siguiente a los alumnos que no cumplan
                    con los requisitos mínimos para educarse en él,<span>&nbsp;&nbsp; </span>o en
                    caso de estimar que el mismo podría dar más de sí en otro Colegio o ambiente más
                    adecuado a él.-
                  </span>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-TRAD">
                    Teniendo en cuenta el estilo educativo del Colegio, se establece la siguiente
                    gradualidad:
                  </span>
                </div>
                <ol>
                  <li>
                    <span lang="ES-AR">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">Diálogo y Consejo;</span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">Exhortación al cambio de actitud;</span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      Llamado de atención por escrito en el Cuaderno de comunicaciones y
                      notificación a los padres o tutores por faltas leves;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">Amonestaciones, en el nivel que correspondiere;</span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">Separación temporal del Establecimiento;</span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">Pase a otro Establecimiento;</span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">Otros, según criterio de las Autoridades.-</span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 8º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    :<b>FALTAS:</b>
                  </span>
                </div>
                <ol>
                  <li>
                    <span lang="ES-TRAD">
                      Para determinar si la falta es leve o grave las Autoridades del Colegio
                      considerarán: contexto y gravedad del hecho, antecedentes disciplinarios del
                      alumno, reiteración del hecho, actitud del alumno frente al mismo, y demás
                      pautas cuya discrecionalidad corresponde a las Autoridades.-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Las sanciones que se aplicaren a los alumnos, tendrán una finalidad correctiva
                      respecto del mismo y una finalidad de seguridad y salvaguarda respecto de la
                      comunidad educativa.-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      A los tres llamados de atención por faltas leves y/o al primer llamado de
                      atención por faltas graves, corresponderán medidas disciplinarias mayores.- En
                      caso de llamados de atención, amonestaciones y/o separaciones temporales del
                      establecimiento, el padre o tutor deberá notificarse de la medida tomada.-{" "}
                    </span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-AR">FALTAS LEVES</span>
                  </b>
                  <span lang="ES-AR">:</span>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-AR">Serán consideradas faltas leves:</span>
                </div>
                <ol>
                  <li>
                    <span lang="ES-AR">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">
                      Provocar desorden en el aula, sala de computación, sala de video, laboratorio,
                      etc., impidiendo al docente el normal desarrollo de la clase;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">
                      Presentarse sin el uniforme oficial del Colegio, con el uniforme incompleto
                      y/o en malas condiciones;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      No hacer firmar por los padres o tutores las comunicaciones enviadas por el
                      Colegio;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">
                      Presentarse al Colegio con joyas, maquillaje y/o peinados extravagantes;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">
                      Hacer bromas o colocar apodos que hieran los sentimientos de los compañeros de
                      la comunidad educativa;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      Ingresar tarde a las clases después de los recreos y/o cambios de horas, así
                      como ausentarse de las mismas sin permiso del docente y/o preceptor;
                    </span>
                  </li>
                  <li>
                    N
                    <span lang="ES-TRAD">
                      o devolver dentro de las 48 horas cualquier documentación que le haya sido
                      entregada (Boletín de calificaciones, Cuaderno de comunicaciones, etc.);
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      No traer el Cuaderno de comunicaciones o presentarlo en mal estado;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      Dañar y/o esconder elementos de limpieza, debiendo reponer lo roto o
                      deteriorado;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">
                      <span></span>Ensuciar y/o escribir paredes, pupitres, baños, pizarras, etc.;
                      sin perjuicio de considerarse falta grave si lo escrito fueren insultos,
                      agravios y/u obscenidades;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      <span></span>Cualquier otra actitud, conducta o comportamiento que altere el
                      orden y/o <span>&nbsp;&nbsp;</span>la disciplina del Colegio.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      Respecto de las llegadas tardes a clase y de las correspondientes sanciones,
                      se detalla: si el retraso fuere de 15 minutos se aplicará un llamado de
                      atención oral; si lo fuere de 30 minutos el llamado de atención será escrito;
                      si se tratare de más de 30 minutos se aplicarán amonestaciones a criterio del
                      docente y/o directivo.<span>&nbsp;</span>
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span></span>Utilizar cualquier recurso tecnológico, cuando fuere permitido o
                      requerido por la actividad a desarrollar, fuera de la clase para cuya
                      finalidad didáctica fuere solicitado.
                    </span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">FALTAS GRAVES:</span>
                  </b>
                </div>
                <ol>
                  <li>
                    <span lang="ES-AR">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">Realizar una falta leve más de tres veces;</span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">
                      Causar desordenes y/o disturbios en un acto oficial, dentro o fuera del
                      Colegio;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">
                      Negarse a firmar el libro de disciplina, comunicaciones y/o a acatar las
                      normas del Colegio y/o de sus autoridades;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      Agredir física o verbalmente y contestar en forma inadecuada a cualquier
                      miembro de la comunidad educativa;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      Faltar el respeto a los compañeros y/o pronunciar palabras obscenas;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">
                      Adulterar, falsificar o sustraer documentos del Colegio, libros de temas,
                      informes, comunicaciones, evaluaciones y/o cualquier documento oficial;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      Fumar dentro de las instalaciones del Colegio así como dentro de los espacios
                      libres comprendidos en su predio;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      Portar y/o traficar cualquier tipo de drogas, estupefacientes, estimulantes,
                      bebidas alcohólicas y/o cigarrillos.-{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      Portar y/o hacer uso de objetos cortantes, armas de todo tipo, materiales
                      explosivos, inflamables y de cualquier otra naturaleza que sean susceptibles
                      de poner en peligro la integridad física y/o seguridad de las personas e
                      instalaciones de la comunidad educativa;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">
                      <span></span>Ocasionar daños y/o deterioros en los bienes del Colegio que
                      hacen a su normal funcionamiento (bancos, sillas, computadoras, libros,
                      pizarras, etc.) así como también respecto del edificio (ventanas, puertas,
                      vidrios, revoques, pinturas, etc.);
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      <span></span>Cometer engaños y/o fraudes en el trabajo escolar, sea por copia,
                      presentación de trabajos ajenos, presentación de trabajos de años anteriores,
                      etc.;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      <span></span>Ocasionar daños en los bienes de cualquier miembro de la
                      comunidad escolar;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      <span></span>Arrojar elementos que pudieren dañar a compañeros, docentes,
                      preceptores, autoridades y demás miembros de la comunidad educativa (tizas,
                      borradores, piedras, etc.);
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      <span></span>Burlarse, hacer señas o gestos indebidos a las autoridades del
                      Colegio;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-AR">
                      <span></span>Portar cualquier tipo de material obsceno;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      <span></span>Sustraer pertenencias del Colegio o de integrantes de la
                      comunidad escolar;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      <span></span>Salir de su domicilio para asistir al colegio y quedarse en algún
                      otro lugar sin el consentimiento de sus padres o tutores, así como retirarse
                      del colegio sin la autorización de las autoridades del mismo; aclarándose que
                      los posteriores argumentos verbales de los padres o tutores carecerán de
                      validez, salvo cuando vengan acompañados de documentación justificativa
                      respaldatoria.-{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <b>
                      <span lang="ES-TRAD">
                        <span></span>
                      </span>
                    </b>
                    <span lang="ES-TRAD">
                      Estar ausente en las horas de clase escondiéndose en cualquier lugar y/o
                      dependencia del Establecimiento.{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      Faltar al respeto, blasfemar, y/o dañar de cualquier manera, en todo o en
                      parte, las imágenes religiosas y/o símbolos patrios.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-TRAD">
                      <span>
                        <span></span>
                      </span>
                    </span>
                    <span lang="ES-TRAD">
                      <span></span>Cualquier otro comportamiento inadecuado dentro del Colegio,
                      entendiéndose que las faltas graves enumeradas son al solo efecto
                      ejemplificativo y que podrá considerarse como tal cualquier otro hecho o
                      conducta del alumno que a juicio de las autoridades revista entidad tal que lo
                      haga susceptible de ser tomado como falta grave.-{" "}
                    </span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 9º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    :<b>ELECCIÓN DE ABANDERADOS:</b>
                  </span>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-TRAD">
                    Dado que el honor de ser portador de las enseñas Patria y Papal, debe ser
                    accesible a los alumnos más meritorios, será designado abanderado el alumno que
                    reúna las siguientes condiciones:
                  </span>
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-AR">
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                  </b>
                </div>
                <ul>
                  <li>
                    <span lang="ES-AR">
                      <span>
                        a)
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </span>
                    </span>
                    <span lang="ES-AR">Rendimiento académico.-</span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      <span>
                        b)
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </span>
                    </span>
                    <span lang="ES-AR">Amor a la Patria y Testimonio de vida cristiana;</span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      <span>
                        c)
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </span>
                    </span>
                    <span lang="ES-AR">
                      Conducta (no registrar sanciones disciplinarias –llamados de atención escritos
                      y/o amonestaciones a lo largo de la Educación) asistencia, puntualidad y
                      aplicación.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      <span>
                        d)
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </span>
                    </span>
                    <span lang="ES-AR">Solidaridad con la obra del establecimiento;</span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      <span>
                        e)
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </span>
                    </span>
                    <span lang="ES-AR">Aseo y presentación.-</span>
                    <b>
                      <span lang="ES-AR">
                        <span>&nbsp;</span>
                      </span>
                    </b>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      f
                      <b>
                        )<span>&nbsp; </span>
                        <span>&nbsp;&nbsp; </span>
                      </b>
                      Compromiso en el servicio al prójimo;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      g) <span>&nbsp;</span>&nbsp; Actitud receptiva al diálogo con las hermanas,
                      profesores y miembros de la comunidad educativa.-
                    </span>
                  </li>
                </ul>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 10º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    :<b>DEBERES DE LOS PADRES Y/O TUTORES:</b>
                  </span>
                </div>
                <ol>
                  <li>
                    <span lang="ES-AR">
                      Conocer con claridad los objetivos y valores de vida que propone el Colegio
                      así como su modelo educativo, los que deberá aceptar y respaldar.-{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Asumir su tarea de principales educadores de sus hijos y/o pupilos,
                      colaborando activamente en la tarea formativa.-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Controlar el rendimiento de sus hijos, disciplina y asistencia a clase, notas
                      y Cuaderno de comunicaciones.- Exigir de ellos la máxima responsabilidad en el
                      cumplimiento de sus obligaciones. Cumplir en tiempo y forma con toda
                      información o documentación requerida.-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Informar a las autoridades escolares todo acontecimiento o circunstancia
                      favorable o desfavorable que pueda tener in-fluencia en el rendimiento de la
                      conducta del alumno.-{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Pagar en tiempo y forma los aranceles fijados por el Colegio, conforme lo
                      establecido en el art. 14.-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Arbitrar los medios para que los alumnos puedan cumplir con los horarios
                      establecidos por el Colegio; evitar las inasistencias o tardanzas; justificar
                      y notificar en tiempo y forma las mismas; no retirar a sus hijos del colegio
                      antes del horario de salida, salvo casos de estricta necesidad.-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Proporcionar a sus hijos el uniforme pedido por el Colegio, evitando toda
                      singularidad al respecto.-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Aceptar el presente Contrato Educativo, así como las disposiciones adoptadas
                      por las autoridades del colegio, cumplirlas y hacerlas cumplir por sus hijos,
                      bajo pena de considerar rescindido el presente por su exclusiva culpa.-----
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Aceptar el presente documento de Normas de Convivencia, así como las
                      disposiciones adoptadas por las autoridades del Colegio, cumplirlas y hacerlas
                      cumplir por sus hijos, bajo pena de considerar rescindida la vacante por su
                      exclusiva responsabilidad.{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Comunicar inmediata y fehacientemente todo cambio y/o modificación del
                      domicilio, teléfono y demás datos tanto del Padre o Tutor como del alumno.
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">Respetar horarios de consultas.</span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Respetar los horarios de entrada y salida de cada turno responsabilizándose
                      por la puntualidad de sus hijos.{" "}
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Asistir en tiempo y en forma cada vez que sea convocado por el Colegio.
                    </span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 11º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    :<b>VIAJES:</b>
                  </span>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-AR">
                    La institución promoverá los viajes educativos, en los distintos niveles, que
                    fomenten el crecimiento cultural, intelectual, social, deportivo y espiritual de
                    los alumnos; más no propicia ningún tipo de viajes de egresados, los que caso de
                    efectuarse lo serán bajo la exclusiva responsabilidad de los Padres o Tutores y
                    quedando el Colegio exonerado de toda responsabilidad al respecto, como así
                    también quedará exento de toda responsabilidad por los daños y perjuicios que
                    pudieren ocasionarse al grupo comprendido en el viaje y/o a terceros, por o en
                    ocasión del mismo o sus circunstancias, sean tales daños en las personas o en
                    los bienes.-
                  </span>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-AR">
                    Las inasistencias al grupo que efectúe el viaje de egresados serán computadas
                    como cualquier inasistencia a clase no justificada.-
                  </span>
                </div>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 12º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    :<b>VISITAS:</b>
                  </span>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-AR">
                    Ninguna persona ajena a la Institución podrá visitar a los alumnos en el horario
                    escolar, buscarlos en las aulas ni permanecer en las instalaciones donde
                    funcionen las mismas.-
                  </span>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-AR">
                    Cualquier persona ajena a la Institución que desee visitar a los alumnos en el
                    horario escolar, retirarlos o acercarles alimento o útiles escolares deberá
                    contar con el permiso de las autoridades anunciando previamente su intención o
                    necesidad.-----
                  </span>
                </div>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 13º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    :<b>REINSCRIPCION:</b>
                  </span>
                  <span lang="ES-AR"></span>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-AR">
                    Se deja expresa constancia, que una vez vencido el presente Contrato Educativo,
                    el Colegio se reserva el derecho de admisión conforme lo normado por los Arts.
                    896/897 del C.C., y especialmente en los casos que seguidamente se mencionan:
                  </span>
                </div>
                <ol>
                  <li>
                    <span lang="ES-AR">
                      Respecto de los alumnos que durante un período lectivo anterior hayan
                      presentado problemas de conducta, disciplina y/o aplicación, o que hayan
                      cometido faltas graves y/o incumplido los deberes a su cargo que surgen del
                      Contrato Educativo y Normas de Convivencia que lo sustentan;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      <span></span>Respecto de los alumnos cuyos padres no hayan dado cumplimiento a
                      una y/o algunas de las obligaciones a su cargo emergentes del contrato
                      educativo o hayan ocasionado dificultades ante las suposiciones emanadas de
                      las autoridades de la Institución;
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Respecto de aquellos alumnos que repitieren el curso cuando el Colegio tiene
                      el cupo de vacantes cubierto.
                    </span>
                  </li>
                </ol>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTÍCULO 14º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    :<b>ARANCELES:</b>
                  </span>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-TRAD">
                    El Colegio<b>, </b>
                  </span>
                  <span lang="ES-AR">
                    al ser una Institución de carácter privado incorporado a la enseñanza oficial,
                    recibe un aporte del Gobierno de la Provincia de San Luis, que no cubre la
                    totalidad de los sueldos del personal docente, administrativo y auxiliar; de
                    allí que sea necesario el pago en término de los aranceles vigentes (matrícula y
                    diez cuotas mensuales, cuyos importes son variables y serán ajustados conforme
                    la realidad económica del País).-
                  </span>
                </div>
                <div class="MsoNormal">
                  <span lang="ES-AR">
                    Las matrículas de inscripción y reinscripción así como los aranceles educativos
                    mensuales, deberán ser abonados en tiempo y forma.- En caso de mora, la que se
                    producirá de pleno derecho por el solo vencimiento del plazo estipulado, las
                    sumas adeudadas generarán los intereses especificados en el anexo arancelario.-
                    Asimismo la mora así como la no cancelación anual de las cuotas, implicarán la
                    pérdida de la vacante.-
                  </span>
                </div>
                <ul>
                  <li>
                    <span lang="ES-AR">
                      Queda aclarado que el Colegio cobrará por cada educando una Matrícula y 10
                      cuotas<span>&nbsp;</span>pagaderas de Marzo a Diciembre del ciclo lectivo en
                      curso, y en los meses de Julio y Agosto corresponde el pago de cuota y media,
                      en concepto de aranceles de enseñanza.-
                    </span>
                  </li>
                  <li>
                    <span lang="ES-AR">
                      Asimismo las fechas de inscripción, valor de los aranceles, descuentos por
                      pagos en única vez, sistemas de becas, etc., serán comunicados a cada Padre o
                      Tutor que lo solicitare oportunamente.-
                    </span>
                  </li>
                </ul>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTICULO 15º) VIGENCIA:</span>
                  </b>
                  <span lang="ES-TRAD">
                    {" "}
                    El presente Contrato Educativo, basado en las Normas de Convivencia establecidas
                    entre La Institución, los educandos y sus Padres y/o Tutores en el marco de un
                    mutuo acuerdo de armónica relación interpersonal,<span>&nbsp; </span>tendrá
                    vigencia durante el ciclo lectivo en curso, y no se renovará automáticamente.-
                  </span>
                </div>
                <div class="MsoNormal">
                  <br />
                </div>
                <div class="MsoNormal">
                  <b>
                    <span lang="ES-TRAD">ARTICULO 16º)</span>
                  </b>
                  <span lang="ES-TRAD">
                    Las partes constituyen domicilio a los fines contractuales en los ya
                    consignados, renunciando al fuero federal y sometiéndose a la Jurisdicción de
                    los Tribunales Ordinarios de la Ciudad de Villa Mercedes (San Luis).-
                  </span>
                </div>

                {/* <div class="MsoNormal">
                  <span lang="ES-AR">
                    Aceptando íntegramente el presente contrato educativo del Instituto Sagrado
                    Corazón, fundado en la buena fe y normas de convivencia pactadas, suscribo el
                    mismo de conformidad, en la Ciudad de Villa Mercedes, Provincia de San Luis de
                    la República Argentina, a los .. días del mes de …………………………………. del año Dos Mil
                    Trece.-
                  </span>
                </div> */}
              </div>
            </div>
          </Grid>
        </Grid>
      </ContenedorPrincipal>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <BotonWsp />
    </>
  );
}

export default NormasDeConvivencia;
