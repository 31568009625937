// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import isc1 from "assets/images/isc1.jpg";
import isc2 from "assets/images/isc2.jpg";
import isc3 from "assets/images/isc3.jpg";
import fotoFrente from "assets/images/historiaISC.jpg";
import { ContenedorPrincipal } from "components/ContenedorPrincipal";
import BotonWsp from "components/BotonWsp";

function HistoriaISC() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky brand={"Instituto Sagrado Corazón"} />
      <MKBox
        minHeight="65vh"
        width="100%"
        sx={{
          backgroundImage: `url(${fotoFrente})`,
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          backgroundRepeat: "no-repeat",
        }}
        borderRadius="lg"
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKBox textAlign="center">
              <MKTypography
                variant="h1"
                color="primary"
                mt={-6}
                mb={1}
                fontWeight="bold"
                fontFamily="Montserrat"
                fontSize={{ xs: "2rem", xl: "4rem", lg: "3rem" }}
                sx={
                  (({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  }),
                  {
                    textShadow:
                      "2px 2px 2px black, -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black",
                  })
                }
              >
                Historia{" "}
              </MKTypography>
              <MKTypography
                variant="h4"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
                sx={{
                  textShadow:
                    "2px 2px 2px black, -2px -2px 2px black, 2px -2px 2px black, -2px 2px 2px black",
                  fontFamily: "-apple-system",
                }}
              ></MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <ContenedorPrincipal>
        <MKBox textAlign="center" py={3}>
          <MKTypography variant="h1" fontWeight="medium" color="primary">
            Colegio Sagrado Corazón de Jesús
          </MKTypography>
        </MKBox>
        <Grid px={3} container spacing={1}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <div>
              <MKTypography>
                &nbsp; La memoria de nuestro colegio comienza de la siguiente manera: “Año del señor
                1901. Fundación y apertura del Colegio Sagrado Corazón de Jesús en Villa Mercedes.
                San Luis. República Argentina.” Nuestra Madre, Francisca Javier Cabrini, después de
                su llegada a la Argentina se dispuso a fundar el Instituto para gloria del Sagrado
                Corazón.
              </MKTypography>
              <MKTypography>
                &nbsp; La propuesta de fundar una nueva casa surgió cuando Mercedes de la Reta,
                compañera de viaje de Italia a Buenos Aires, le dijo que estaba dispuesta a ofrecer
                parte de su patrimonio para que fundara un colegio en su ciudad natal (Mendoza).
                Ante tal ofrecimiento y una vez en Buenos Aires, la religiosa dispuso enviar dos
                hermanas a la ciudad de Mendoza para ultimar detalles de la nueva casa; luego de
                cuarenta días en dicha ciudad pierden las esperanzas por aparente cambio de voluntad
                y con expresa autorización de la Madre abandonan la ciudad; el 13 de abril de 1901
                llegan a Villa Mercedes.
              </MKTypography>
              <MKTypography>
                &nbsp; Las hermanas Constanza Cambieri y Anna Lombardi se dirigieron a la Parroquia
                donde el cura párroco les entregó cartas de recomendación para presentarse en los
                hogares más católicos y algunos propietarios pudientes del medio. Las hermanas en
                pocos días obtienen dinero y muebles para dar comienzo al Colegio; avisada de todo
                Madre Cabrini las animaba a proseguir hasta que pudiera ella llegar a establecer de
                hecho la fundación.
              </MKTypography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <img
              src={isc1}
              alt="Madre Cabrini"
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
          </Grid>
          <MKTypography>
            &nbsp; La Madre Cabrini ocupada en la fundación de otro Colegio en la ciudad de Rosario
            (Santa Fe) retardó su viaje. Llega a Villa Mercedes el día 23 de Mayo de 1901 a las
            cuatro y media de la tarde siendo cálidamente recibida por las dos hermanas, un grupo de
            niñas mercedinas (futuras alumnas del Colegio) y distinguidas señoras del medio. La
            Madre se ocupó personalmente de la búsqueda y elección del edificio, sito en la esquina
            actual de Pedernera y Pringles; antes de las veinticuatro horas siguientes estaba listo
            para iniciar su nueva fundación.
          </MKTypography>
          <Grid item xs={12}>
            <MKBox textAlign="center" p={2}>
              <MKTypography variant="h4" fontWeight="medium" color="primary">
                Apertura del Colegio:
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <div>
              <MKTypography>
                &nbsp; El 26 de Mayo, la Madre Cabrini participó de la misa de pentecostés y a su
                regreso escribió al Señor Obispo Marcelino Benaventi, notificándolo de la apertura
                del Colegio y pidiéndole el confesor y el Capellán. Ese día fue a la estación a
                recibir a las hermanas que desde Buenos Aires venían para formar la comunidad de
                Mercedes. Luego de varios días de estancia, la Superiora regresa a Buenos Aires,
                pese a que por aquellos días nuestra ciudad contaba con la zona centro y estación;
                al pasar por el sitio donde está el actual colegio (Mitre 1222) Madre Cabrini dejó
                claro que ese era el lugar que le agradaba para construir el colegio, pues
                consideraba que ese era el centro geográfico de la villa, sueño que se hizo realidad
                en la década del 60.
              </MKTypography>
              <MKTypography>
                &nbsp; La comunidad estaba formada por siete religiosas a las que se agregaron dos
                más en junio de ese año. El trabajo de la casa era mucho: visitar enfermos, enseñar
                el catecismo, refaccionar la casa, visitar los presos, hacer escuchar la palabra de
                Dios, misionar. Nuestro colegio se convirtió en poco tiempo en un importante centro
                de catequización de los inmigrantes italianos y del resto de la población. Los
                primeros años transcurren sin grandes novedades; la nueva casa se distinguía por el
                elevado nivel de enseñanza y la difusión de dos lenguas extranjeras: italiana y
                francesa. Así fue pasando el tiempo y el colegio fue creciendo en número de alumnos
                y para proveerse del mobiliario y realizar los arreglos de la casa (pobre,
                construida de adobe) se ayudaban con rifas y beneficios.
              </MKTypography>
              <MKTypography>
                &nbsp; Años subsiguientes: La pujante escuela primeria de las misioneras recibían
                año a año, mayor cantidad de alumnos, junto con el número de alumnos crece la
                necesidad de más docentes, así se abren las puertas a las primeras educadoras
                laicas. En la década del cuarenta, el instituto comienza a dictar clases de bordado
                y cocina, lo que redituaba a las hermanas para su manutención. Es también a fines de
                la década del cuarenta que la escuela recibe los primeros alumnos varones que solo
                cursarían hasta primer grado superior.
              </MKTypography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <img
              src={isc2}
              alt="Madre Cabrini"
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <div>
              <MKTypography>
                &nbsp; En los años cincuenta se ve la necesidad de construir un edificio más grande,
                comienza así el sueño del nivel secundario. El colegio se construiría en el terreno
                elegido por madre Cabrini cincuenta años atrás, a través de la donación del terreno
                y dinero para la construcción del edificio por parte de los hermanos Boucherville.
              </MKTypography>
              <MKTypography>
                &nbsp; Crece la construcción de la nueva casa con la planta alta lista para una
                escuela secundaria y abre sus puertas en 1962. El nuevo edificio albergó durante
                años a niñas de poblaciones cercanas donde no podían realizar estudios secundarios.
                Las conocidas pupilas estuvieron en el colegio hasta fines de los 80. Hasta 1977 la
                Secretaría y la Dirección del instituto se mantuvo específicamente a cargo de las
                hermanas, pese a que casi el cien por ciento de las cátedras estaban en manos de
                laicos.
              </MKTypography>
              <MKTypography>
                &nbsp; El 2 de Abril de 1983 las misioneras ponen, en marcha en la casa mercedina,
                el primer profesorado de Historia de la provincia de San Luis, el nivel terciario se
                mantuvo hasta cubrir las necesidades del medio. Las promociones de maestras fueron
                reemplazadas por las de bachilleres nacionales y en 1971 se abre el primer año para
                aspirantes a peritos mercantiles, cuya primera promoción deja las aulas en 1975.
                Tanto bachilleres como peritos mercantiles dejan su lugar a bachilleres con
                capacitación laboral en: Auxiliar en Comunicación Social y Auxiliar Contable
                Impositivo.
              </MKTypography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <img
              src={isc3}
              alt="Madre Cabrini"
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
          </Grid>
          <MKTypography>
            &nbsp; En la actualidad el Nivel Secundario del ISC, ofrece un Ciclo Básico Común y un
            Ciclo Orientado en “Economía y Gestión de las Organizaciones”.
          </MKTypography>
        </Grid>
      </ContenedorPrincipal>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <BotonWsp />
    </>
  );
}

export default HistoriaISC;
