// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";
import Noticias from "pages/Noticias";
import MadreCabrini from "pages/MadreCabrini";
import HistoriaISC from "pages/HistoriaISC";
import NormasDeConvivencia from "pages/NormasConvivencia";
import EstructuraCurricular from "pages/EstructuraCurricular";

const routes = [
  {
    name: "Institucional",
    icon: <Icon>location_city</Icon>,
    collapse: [
      {
        name: "Novedades",
        route: "/institucional/novedades",
        component: <Noticias />,
      },
      {
        name: "Madre Cabrini",
        route: "/institucional/madreCabrini",
        component: <MadreCabrini />,
      },
      {
        name: "Historia del ISC",
        route: "/institucional/historiaISC",
        component: <HistoriaISC />,
      },
      {
        name: "Fotogalería",
        href: "https://fotogaleriaisc.blogspot.com/",
      },
    ],
  },
  {
    name: "Primario",
    icon: <Icon>person</Icon>,
    // columns: 1,
    // rowsPerColumn: 2,
    // collapse: [
    //   {
    //     name: "Principal",
    //     collapse: [
    //       {
    //         name: "Princi",
    //         route: "/primaria/",
    //         component: <Primaria />,
    //       },
    //     ],
    //   },
    //   {
    //     name: "account",
    //     collapse: [
    //       {
    //         name: "Ejemplo",
    //         route: "/primaria/ejemplo",
    //         component: <Primaria />,
    //       },
    //     ],
    //   },
    // ],
  },
  {
    name: "Secundario",
    icon: <Icon>person</Icon>,
    collapse: [
      {
        name: "Normas de convivencia",
        route: "/secundario/normasDeConvivencia",
        component: <NormasDeConvivencia />,
      },
      {
        name: "Estructura curricular",
        route: "/secundario/estructuraCurricular",
        component: <EstructuraCurricular />,
      },
    ],
  },
  {
    name: "Misión",
    icon: <Icon>church</Icon>,
    collapse: [
      // {
      //   name: "Madre Cabrini",
      //   route: "/institucional/madreCabrini",
      //   component: <MadreCabrini />,
      // },
      {
        name: "Mural",
        href: "https://iscpastoral.blogspot.com/",
      },
    ],
  },
  // {
  //   name: "noticias",
  //   icon: <Icon>newspaper</Icon>,
  //   route: "/noticias",
  //   component: <Noticias />,
  // },
  {
    name: "Administrar",
    icon: <Icon>login</Icon>,
    description: "Ingresar como administrador",
    href: "https://sagrado-login.vercel.app/",
  },
];

export default routes;
