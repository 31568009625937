// Material Kit 2 React components
import MKBox from "components/MKBox";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { database } from "../../../firebase";
import { useEffect, useState } from "react";
import MKTypography from "components/MKTypography";
import { CircularProgress } from "@mui/material";

function NoticiasFetch() {
  const [noticias, setNoticias] = useState([]);
  const [loading, setLoading] = useState(false);

  const getNoticias = async (page) => {
    setLoading(true);
    try {
      let initialQuery = query(
        collection(database, "noticias"),
        orderBy("fecha", "desc"),
        limit(20)
      );

      const documentSnapshots = await getDocs(initialQuery);
      const noticiasData = documentSnapshots.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      setNoticias(noticiasData);
      // Update the lastVisible to the last document in the current query
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };
  const renderDate = (fecha) => {
    if (fecha) {
      const milliseconds = fecha.seconds * 1000;
      const date = new Date(milliseconds);

      const getMonthName = (month) => {
        const monthNames = [
          "enero",
          "febrero",
          "marzo",
          "abril",
          "mayo",
          "junio",
          "julio",
          "agosto",
          "septiembre",
          "octubre",
          "noviembre",
          "diciembre",
        ];
        return monthNames[month];
      };

      const day = date.getDate();
      const month = getMonthName(date.getMonth());
      const year = date.getFullYear();

      const formattedDate = `${day} de ${month} de ${year}`;

      return formattedDate;
    } else {
      return "-";
    }
  };
  useEffect(() => {
    getNoticias();
  }, []);
  return (
    <MKBox component="section" py={6}>
      <MKBox textAlign="center">
        <MKTypography variant="h1" fontWeight="medium" color="primary">
          Novedades
        </MKTypography>
      </MKBox>
      {loading ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "40vh",
          }}
        >
          <CircularProgress size={100} />
        </MKBox>
      ) : noticias.length > 0 ? (
        <MKBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          {noticias.map((noticia, index) => (
            <MKBox
              key={index}
              my={10}
              sx={{
                width: { xs: "90%", sm: "80%", md: "70%" },
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                borderRadius: "10px",
                "& ul": {
                  listStylePosition: "inside",
                },
                border: "3px solid black",
              }}
            >
              <MKBox px={2} pt={2}>
                <MKTypography variant="h6" fontWeight="medium" color="secondary">
                  {renderDate(noticia.fecha)}
                </MKTypography>
              </MKBox>
              {/* <MKTypography variant="h2" fontWeight="bold">
                {noticia.titulo}
              </MKTypography> */}
              <MKBox
                p={2}
                dangerouslySetInnerHTML={{
                  __html: `<div style="list-style-position: inside;">${noticia.contenido}</div>`,
                }}
              />
            </MKBox>
          ))}
          {/* <MKBox
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderRadius: "10px",
              border: "1px solid black",
            }}
            p={1}
          >
            <MKTypography
              variant="h5"
              fontWeight="bold"
              color="primary"
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "secondary",
                },
              }}
              component={Link}
              to="/noticias"
            >
              Ver mas novedades &nbsp;
              <Icon>redo</Icon>
            </MKTypography>
          </MKBox> */}
        </MKBox>
      ) : (
        <MKBox display="flex" justifyContent="center">
          <MKTypography variant="h6" fontWeight="medium" color="SECONDARY">
            Por el momento no hay novedades.
          </MKTypography>
        </MKBox>
      )}
    </MKBox>
  );
}

export default NoticiasFetch;
