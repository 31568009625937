// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logo150 from "assets/images/logo150.png";
import { Instagram } from "@mui/icons-material";
export default {
  brand: {
    name: "Instituto Sagrado Corazón",
    image: logo150,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/pages/Instituto-Sagrado-Coraz%C3%B3n/102081323166346",
    },
    {
      icon: <Instagram />,
      link: "https://www.instagram.com/institutosagradocorazon.vm",
    },
    {
      icon: <Instagram />,
      link: "https://www.instagram.com/ateneosc",
    },
  ],
  menus: [
    {
      name: "Otros links",
      items: [
        { name: "Blog", href: "https://iscvillamercedes.blogspot.com/" },
        { name: "Instagram ISC", href: "https://www.instagram.com/institutosagradocorazon.vm" },
        { name: "Instagram Ateneo", href: "https://www.instagram.com/ateneosc" },
        // { name: "Mural", href: "https://iscpastoral.blogspot.com/" },
        {
          name: "Misioneras del Sagrado Corazón de Jesús",
          href: "https://equiformmsc.blogspot.com/",
        },
        { name: "Fotogalería", href: "https://iscvillamercedes.blogspot.com/p/fotogaleria.html" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Av. Mitre 1222 - Villa Mercedes, San Luis, Argentina
    </MKTypography>
  ),
};
